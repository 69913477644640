import { useEffect, useState } from "react";
import { useModal } from "../../hooks/modal";
import englishManual from "../../userManual/user_manual_english_2023_05_10_V1.pdf";
import italianManual from "../../userManual/user_manual_italian_2023_05_10_V1.pdf";

const UserManualModal = ({ onCloseCallback }) => {
  const [activeLang, setActiveLang] = useState(null);
  const modal = useModal();

  useEffect(() => {
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);

  const downloadUserManual = () => {
    if (!activeLang) return;
    var link = document.createElement("a");
    link.download = activeLang === "italian" ? italianManual : englishManual;
    link.href = activeLang === "italian" ? italianManual : englishManual;
    link.target = "_self";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className={"modal fade modal-download modal-download-user-manual " + (modal.isVisible ? "show" : "")}
      id="cancelSubscription"
      style={{ display: modal.isVisible ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered  modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="mb-0">
              In which language would you like to download your User Manual Guide <br /> for 3DFrame?
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                modal.hide();
                onCloseCallback();
                setActiveLang(null);
              }}
            ></button>
          </div>
          <div className="modal-body pt-0 ">
            <div className="row flex-container">
              <div className="width-lg-38">
                <div className={`card link ${activeLang === "italian" ? "active" : ""}`} onClick={() => setActiveLang("italian")}>
                  <div className="card-thumb">
                    <i className={`icon-product-new icon-intalian-version  ${activeLang === "italian" ? "active" : ""}`} />
                  </div>
                  <h5 className="link" onClick={() => setActiveLang("italian")}>
                    Italian Version
                  </h5>
                </div>
              </div>

              <div className="width-lg-38">
                <div className={`card link ${activeLang === "english" ? "active" : ""}`} onClick={() => setActiveLang("english")}>
                  <div className="card-thumb">
                    <i className={`icon-product-new icon-english-version ${activeLang === "english" ? "active" : ""}`} />
                  </div>
                  <h5 className="link" onClick={() => setActiveLang("english")}>
                    English Version
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary pl-xs-4 pr-xs-4"
              data-bs-dismiss="modal"
              onClick={() => {
                if (!activeLang) return;
                downloadUserManual(activeLang);
                modal.hide();
                setActiveLang(null);
                onCloseCallback();
              }}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserManualModal;
