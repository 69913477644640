export default function ReviewOrderStep3(props) {
  const { plan, card } = props;
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="spacer-xs-3"></div>
          <div className="table-wrapper table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <td width="36%">Plan:</td>
                  <td className="text-capitalize">{plan ? plan : ''}</td>
                </tr>
                <tr>
                  <td width="36%">Amount:</td>
                  <td className="text-capitalize">{plan === 'monthly' ? '$50/month (per license)' : '$40/month (per license) - saved 20%'}</td>
                </tr>
                <tr>
                  <td width="36%">Payment Method:</td>
                  <td className="text-capitalize">{card ? card.brand + ' **** ' + (card.last4 ? card.last4 : card.card_number) : ''}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
