import { useRef, useState } from 'react';
import { auth } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import eye from '../../images/eye.svg';
import eyeSlash from '../../images/eye-slash.svg';

export default function ChangePasswordPage() {
  const [newPassword, setNewPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  let newPasswordRef = useRef();
  let oldPasswordRef = useRef();
  const navigate = useNavigate();

  const onSubmit = e => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      setError(null);
      setSuccess(null);
      let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?])[A-Za-z\d()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?]{8,}$/;

      if (!passwordExpression.test(newPassword)) {
        setError(
          "Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|`~!@#$%^&*_-+=;:',<>./?"
        );
        setIsLoading(false);
        return true;
      }
      auth.changePassword(
        oldPassword,
        newPassword,
        success => {
          _resetForm();
          setIsLoading(false);
          setSuccess(success.message ? success.message : 'Password updated successfully.');
          setTimeout(() => {
            setSuccess(null);
            navigate('/scene-list');
          }, 3000);
        },
        error => {
          setIsLoading(false);
          setError(error.message ? error.message : 'Password update failed.');
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      );
    }
  };

  const togglePasswordText = type => {
    if (type == 'new') {
      if (isNewPasswordVisible) {
        setIsNewPasswordVisible(false);
        newPasswordRef.current.type = 'password';
      } else {
        setIsNewPasswordVisible(true);
        newPasswordRef.current.type = 'text';
      }
    } else {
      if (isOldPasswordVisible) {
        setIsOldPasswordVisible(false);
        oldPasswordRef.current.type = 'password';
      } else {
        setIsOldPasswordVisible(true);
        oldPasswordRef.current.type = 'text';
      }
    }
  };

  const _resetForm = () => {
    document.getElementById('form').reset();
  };

  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center">
            <div className="width-100 mb-lg-5">
              <div className="form-wrapper">
                <form className="input-from" method="post" onSubmit={onSubmit} id="form">
                  <h1>Change Password</h1>
                  {error ? <div className="text-danger mb-4">{error}</div> : <></>}
                  {success ? <div className="text-success mb-4">{success}</div> : <></>}

                  <div className="form-floating mb-3">
                    <i className="icon-key"></i>
                    <input
                      type="password"
                      className="form-control"
                      required={true}
                      onChange={e => setOldPassword(e.target.value)}
                      // pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      // title="Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol"
                      ref={oldPasswordRef}
                    />

                    <img
                      className="img-fluid"
                      src={isOldPasswordVisible ? eye : eyeSlash}
                      alt="icon-seen"
                      onClick={() => {
                        togglePasswordText('old');
                      }}
                    />
                    <label className="form-label" htmlFor="floatingPassword">
                      Old Password
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <i className="icon-key"></i>
                    <input
                      type="password"
                      className="form-control"
                      required={true}
                      onChange={e => setNewPassword(e.target.value)}
                      pattern="(?=.*\d)(?=.*[!()[\]{}|\\`~!@#$%^&*_\-+=;:',<>./?])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|\`~!@#$%^&*_\-+=;:',<>./?"
                      ref={newPasswordRef}
                    />

                    <img
                      className="img-fluid"
                      src={isNewPasswordVisible ? eye : eyeSlash}
                      alt="icon-seen"
                      onClick={() => {
                        togglePasswordText('new');
                      }}
                    />
                    <label className="form-label" htmlFor="floatingPassword">
                      New Password
                    </label>
                  </div>

                  {/* <div className="spacer-xs-5"></div> */}
                  <button type="submit" className={'btn btn-primary d-flex justify-content-center w-100 ' + (isLoading ? 'disabled text-white' : '')}>
                    {isLoading ? (
                      <>
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                        <span className="sr-only mt-1">&nbsp; Update Password...</span>
                      </>
                    ) : (
                      <span className="sr-only mt-1">&nbsp; Update Password</span>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
