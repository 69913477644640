import { createContext, useContext, useEffect, useState } from 'react';

const modalContext = createContext();

export function ProvideModal({ children }) {
  const modal = useProvideModal();
  return (
    <modalContext.Provider value={modal}>
      <>{children}</>
    </modalContext.Provider>
  );
}

export const useModal = () => {
  return useContext(modalContext);
};

function useProvideModal() {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    document.body.className = '';
    document.getElementById('backdrop') && document.getElementById('backdrop').remove();
    setIsVisible(false);
    return true;
  };

  const show = () => {
    document.body.className = 'modal-open';
    let backdrop = document.createElement('div');
    backdrop.id = 'backdrop';
    backdrop.className = 'modal-backdrop fade show';
    document.body.append(backdrop);
    setIsVisible(true);
    return true;
  };

  useEffect(() => {
    setIsVisible(isVisible);
  }, [isVisible]);

  return {
    isVisible,
    hide,
    show
  };
}
