import { useEffect } from 'react';
import { useModal } from '../../hooks/modal';

const CancelSubscriptionModal = ({ startDate, endDate, handleOk, handleCancel }) => {
  const modal = useModal();

  useEffect(() => {
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);
  return (
    <div className={'modal fade modal-cancel-sub ' + (modal.isVisible ? 'show' : '')} id="cancelSubscription" style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleCancel();
                modal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body pt-0">
            <h4>Please Note:</h4>
            <p>
              Your subscription is paid until <strong>{startDate}</strong>. If you would like to proceed with cancelling today the cancellation will not go into effect until the
              date listed above. You will lose access to your metaverse presentations on <strong>{endDate}</strong>.
            </p>
            <div className="spacer-xs-10"></div>
            <div className="d-grid d-md-flex justify-content-md-end">
              <button
                type="button"
                className="btn btn-danger"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => {
                  handleOk();
                  modal.hide();
                }}
              >
                Cancel Subscription
              </button>
              <button
                className="btn btn-link text-content ps-3 pe-0"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => {
                  handleCancel();
                  modal.hide();
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancelSubscriptionModal;
