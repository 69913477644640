export default function LoaderPage() {
  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center">
            <div className="width-100 text-center">
              {/* <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
