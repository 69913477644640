import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { maintenance } from "../services/maintenance";
import Header from "./Header";
import LoaderPage from "./LoaderPage";
import Maintenance from "./MaintenancePage";

export default function PageLayout() {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    maintenance.getMode(
      (response) => {
        if (response) {
          setDataLoading(false);
          setMaintenanceMode(response.maintenance);
        }
      },
      (error) => {}
    );
    maintenance.getMessage(
      (response) => {
        if (response && response.maintenance_message) {
          setMaintenanceMessage(response.maintenance_message);
        }
      },
      (error) => {}
    );
  });

  return (
    <div className="App">
      <Header maintenanceMode={maintenanceMode} maintenanceMessage={maintenanceMessage} />
      {dataLoading ? <LoaderPage /> : <>{maintenanceMode ? <Maintenance /> : <Outlet />}</>}
    </div>
  );
}
