import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { scene } from '../../services/scene';

export default function AddScene() {
  const [sceneName, setSceneName] = useState(null);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isCreateSceneReqSent, setIsCreateSceneReqSent] = useState(false);
  let navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    setErrorMessage(null);
    setMessage(null);
    if (!isCreateSceneReqSent && sceneName) {
      let formatedFileName = sceneName.replace(/[^a-zA-Z0-9. ]/g, " ").replace(/\s+/g, '-');
      setIsCreateSceneReqSent(true);
      scene.create(
        formatedFileName,
        result => {
          setIsCreateSceneReqSent(false);
          setMessage('SUBMITTED TO QUEUE.');
          setTimeout(() => {
            navigate('/scene-list');
          }, 2000);
        },
        error => {
          _resetForm();
          setIsCreateSceneReqSent(false);
          setErrorMessage(error.message ? error.message : 'Could not create Scene. Try Again!');
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        }
      );
    }
  };

  const _resetForm = () => {
    document.getElementById('add-scene-form').reset();
    setSceneName(null);
  };

  return (
    <PageWrapper>
      <div className="tab-pane fade show active" id="pills-scenes" role="tabpanel" aria-labelledby="pills-scenes-tab">
        <form onSubmit={handleSubmit} id="add-scene-form">
          <div className="create-scene-msg messages text-success">{message}</div>
          <div className="create-scene-msg error-message text-danger">{errorMessage}</div>
          <div className="add-name">
            <div className="row align-items-lg-end g-3">
              <div className="width-lg-83">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Scene Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter Scene Name"
                    required={true}
                    value={sceneName || ""}
                    onChange={e => setSceneName(e.target.value)}
                  />
                </div>
              </div>
              <div className="width-lg-17">
                <button className="btn btn-primary d-flex justify-content-center w-100" type="submit">
                  Start
                </button>
              </div>
            </div>
            <div className="spacer-xs-2"></div>
          </div>
        </form>
      </div>
    </PageWrapper>
  );
}
