import { getAPIPrefix } from '../config/APIEndpoints';
import { _postRequest, _memberOnlyRequest } from './_apiReq';
import CryptoJS from 'crypto-js';
import axios from 'axios';
const key = CryptoJS.enc.Hex.parse('3214865890bac31f');
const iv = CryptoJS.enc.Hex.parse('1234567890abcdef');

export const scene = {
  getPresignUrl(sceneName, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', sceneName);

    _memberOnlyRequest(
      getAPIPrefix() + 'import-scene',
      formData,
      result => cb(result),
      error => errorCb({ message: error.message }),
      'Could not upload the file.Please try again!'
    );
  },
  importDBSave(sceneName, bOverwrite, cb, errorCb) {
    const url = getAPIPrefix() + 'import-scene-save';
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', sceneName);
    formData.append('overwrite', bOverwrite);

    _memberOnlyRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error.message }),
      'Could not save the file.Please try again!'
    );
  },
  getWebList(perPage, page, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append("per_page", perPage);
    formData.append("page_number", page);

    _postRequest(
      getAPIPrefix() + 'web-scene-list',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  getList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      getAPIPrefix() + 'web-scene-list',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  export(sceneName, cb, errorCb) {
    const url = getAPIPrefix() + 'scene-export';
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', sceneName);

    _memberOnlyRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      'Could not found the scene image.Please try again!'
    );
  },
  download(sceneName, cb, errorCb) {
    const url = getAPIPrefix() + 'scene-export-download';
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', sceneName);

    _memberOnlyRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      'Could not download the file.Please try again!'
    );
  },
  copy( oldSceneName, newSceneName, cb, errorCb) {
    const url =  getAPIPrefix() + 'copy-scene';
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('oldSceneName', oldSceneName);
    formData.append('newSceneName', newSceneName);
    formData.append('oldSceneClientId', localStorage.getItem('client_id'));

    _memberOnlyRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      'Could not copy the scene.Try again!'
    );
  },
  delete(directoryName, cb, errorCb) {
    const url =  getAPIPrefix() + 'scene-directory-delete';
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', directoryName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not delete the scene.Please try again!'
    );
  },
  dbList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      getAPIPrefix() + 'scene-list',
      formData,
      result => cb(result.list ? result.list : []),
      error => errorCb({ message: error })
    );
  },
  imagePreview(sceneName, cb, errorCb) {
    const url =  getAPIPrefix() + 'scene-preview';
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', sceneName);

    _postRequest(
      url,
      formData,
      result => {
        if (result.url) {
          this._fetImageData(
            result.url,
            result => {
              cb(result);
            },
            error => {
              errorCb({ message: "no result found." })
            }
          );
        } else {
          errorCb({ message: "no result found." })
        }
      },
      error => errorCb({ message: error }),
      'Could not found the scene image.Please try again!'
    );
  },
  _fetImageData(url, cb, errorCb) {
    fetch(url, {
      method: 'GET'
    })
      .then(response => response.text())
      .then(text => {
        let decrypted = CryptoJS.DES.decrypt(text.replaceAll('\u0000', ''), key, { iv: iv });
        cb(`data:image/jpeg;base64,${decrypted.toString(CryptoJS.enc.Base64)}`);
      })
      .catch(error => {
        errorCb('Invalid request! Please try again.');
      });
  },
  scenePreview(scene, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', scene.scene_name);
    axios({
        method: 'post',
        url: getAPIPrefix() + 'scene-preview',
        data: formData,
        headers: {
            'Content-Type': `multipart/form-data`
        }
    }).then(function (response) {
        cb(response);
    });
  },
  create(sceneName, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', sceneName);
    formData.append('position', []);
    
    _postRequest(
      getAPIPrefix() + 'add-scene',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not create the scene.Please try again!'
    );
  },
  update(sceneName, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', sceneName);
    formData.append('position', []);

    _postRequest(
      getAPIPrefix() + 'scene-update',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not update the scene.Please try again!'
    );
  },
};
