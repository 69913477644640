import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { useAuth } from '../../hooks/auth';
import frameLogo from '../../images/3dframe-logo.png';
import { stripeSubscription } from '../../services/stripeSubscription';
import moment from 'moment';
import CancelSubscriptionModal from '../modals/CancelSubscriptionModal';
import Modal from '../modals/Modal';

export default function Licence() {
  const [plan, setPlan] = useState(null);
  const [modal, setModal] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const [isEnterpriseUser, setIsEnterpriseUser] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    _getInfo();
  }, []);

  const _getInfo = () => {
    stripeSubscription.getInfo(
      response => {
        setPlan({ ...response });
        if (response && response.plan_type === 'Enterprise') {
          setIsEnterpriseUser(true);
        }
      },
      error => {
        console.log('error', error);
      }
    );
  };

  const handleCancelSubscription = () => {
    let modal = (
      <CancelSubscriptionModal
        handleOk={() => {
          stripeSubscription.cancelSubscription(
            response => {
              _getInfo();
            },
            error => {
              console.log('error', error);
            }
          );
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        startDate={plan ? moment.unix(plan.current_period_end).format('DD MMMM YYYY') : ''}
        endDate={plan ? moment.unix(plan.next_invoice_date).format('DD MMMM YYYY') : ''}
      />
    );
    setModal(modal);
  };

  const retrySubscription = e => {
    e.preventDefault();
    setIsRetrying(true);
    stripeSubscription.retrySubscription(
      response => {
        setIsRetrying(false);
        _getInfo();
      },
      error => {
        setIsRetrying(false);
        let RetrySubscriptionErrorModal = (
          <Modal
            message={error.message}
            handleOk={() => {
              setModal(null);
            }}
          />
        );
        setModal(RetrySubscriptionErrorModal);
      }
    );
  };

  return (
    <PageWrapper modal={modal} hasInvoiceLayout={true}>
      <section className="inner licence">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card comman">
                <h1>Active Licence</h1>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="plan-detail">
                      {plan ? (
                        <>
                          <ul className="p-0">
                            <li>
                              <div className="d-flex align-items-center logo">
                                <img src={frameLogo} className="img-fluid" />
                                <div className="d-flex flex-column ms-5">
                                  <h3 className="text-capitalize">{plan.qty}</h3>
                                  <span>Qty</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <h3 className="text-capitalize">{plan.plan_type}</h3>
                              <span className="text-capitalize">Subscription Period</span>
                            </li>
                            {isEnterpriseUser ? (
                              <>
                                <li>
                                  <h3>{plan.creation_date !== null ? moment.unix(plan.creation_date).format('DD MMMM YYYY') : '-'}</h3>
                                  <span>Service Effective Date</span>
                                </li>
                                <li>
                                  <h3>{plan.expiryTime !== null ? moment.unix(plan.expiryTime).format('DD MMMM YYYY') : '-'}</h3>
                                  <span>Service Expiration Date</span>
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  <h3>{plan.current_period_start !== null ? moment.unix(plan.current_period_start).format('DD MMMM YYYY') : '-'}</h3>
                                  <span>Service Effective Date</span>
                                </li>
                                <li>
                                  <h3>{plan.next_invoice_date !== null ? moment.unix(plan.next_invoice_date).format('DD MMMM YYYY') : '-'}</h3>
                                  <span>Next Invoice Date</span>
                                </li>
                                <li>
                                  <h3>{plan.next_invoice_amount ? '$' + plan.next_invoice_amount : 'N/A'}</h3>
                                  <span>Next Invoice Amount</span>
                                </li>
                              </>
                            )}
                          </ul>
                          <div className="d-grid gap-2 d-md-flex justify-content-lg-end justify-content-center">
                            {/* Hide all options for Enterprise user */}
                            {plan.plan_type !== 'Enterprise' && (
                              <>
                                {/* cancelled plan can not be re-subscribed */}
                                {plan.subscription_cancel && moment().unix() < plan.current_period_end ? (
                                  <span
                                    onClick={e => {
                                      retrySubscription(e);
                                    }}
                                    type="button"
                                    className={'btn btn-primary me-md-2 text-white ' + (isRetrying ? 'disabled' : '')}
                                  >
                                    {isRetrying ? (
                                      <>
                                        <div className="spinner-border spinner-border-sm" role="status"></div>
                                        <span className="sr-only mt-1">&nbsp; Retry Subscription...</span>
                                      </>
                                    ) : (
                                      <span className="sr-only mt-1">&nbsp; Retry Subscription</span>
                                    )}
                                  </span>
                                ) : (
                                  <Link
                                    to={'/edit-plan'}
                                    type="button"
                                    className={'btn btn-primary me-md-2 text-white '}
                                    // disabled={plan.next_invoice_date === null ? true : false}
                                  >
                                    Edit Current Plan
                                  </Link>
                                )}

                                {plan.plan_type !== 'Free' && auth.isSubscriptionActive() && plan.next_invoice_date !== null && (
                                  <button className={'btn btn-danger text-white '} type="button" onClick={handleCancelSubscription}>
                                    Cancel Subscription
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <p>No data found.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
}
