import { Outlet, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  let auth = useAuth();
  if (auth.isLoggedIn() || auth.isSubscriptionActive()) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};
export default PrivateRoute;
