import React, { useReducer, useEffect, useState } from 'react';
import { Link  } from 'react-router-dom';
import FileUploadWrapper from './FileUploadWrapper';
import PageWrapper from '../PageWrapper';
import sceneReducer from '../../reducers/sceneReducer';
import { scene } from '../../services/scene';
import DuplicateSceneModal from '../modals/DuplicateSceneModal';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import moment from 'moment';
import axios from 'axios';
import noImageIcon from '../../images/no-image-3dframe.jpg';
import rotateIcon from '../../images/icons8-rotate-64.png';
import warningIcon from '../../images/warning-6-48.png';
import plusPNG from '../../images/plus.png';
import Modal from '../modals/Modal';
import ErrorModal from '../modals/ErrorModal';
import CryptoJS from 'crypto-js';

let perPage = 20;
let currentPage = 1;
export default function Index() {
  const key = CryptoJS.enc.Hex.parse('3214865890bac31f');
  const iv = CryptoJS.enc.Hex.parse('1234567890abcdef');
  const [images, setImages] = useState([]);
  const [modal, setModal] = useState(null);
  const [data, dispatch] = useReducer(sceneReducer, []);
  const [isReqSent, setIsReqSent] = useState(false);

  useEffect(() => {
    if (!isReqSent) {
      setIsReqSent(true);
      dispatch({ type: 'loading', payload: {} });
      _listFiles();
    }
    if (data.listUpdated) {
      _fetchSceneImages(data.items);
    }
  }, [data.listUpdated]);

  const handlePreviousClick = () => {
    currentPage = (currentPage - 1);
    _listFiles();
  };

  const handleNextClick = () => {
    currentPage = (currentPage + 1);
    _listFiles();
  };

  const changeCurrentPage = (page) => {
		currentPage = page;
		_listFiles();
	}

  const _listFiles = async () => {
    // console.log("_listFiles > currentPage", currentPage);
    if (window.location.pathname !== '/scene-list') {
      return;
    }
    dispatch({ type: 'updatingList', payload: {} });
    scene.getWebList(
      perPage,
      currentPage,
      result => {
        const { list, success, totalPages, per_page } = result;
        if(currentPage !== result.currentPage) return true;

        if (success) {
            dispatch({
              type: 'updateList',
              payload: {
                items: list,
                totalPages,
                perPage: per_page
              }
            });
          setTimeout(() => {
            _listFiles();
          }, 5000)
        }
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const _fetchSceneImages = (list) => {
    let tempSceneImages = {...images};
    list &&
    list.forEach((item, index) => {
      if (images[item.id]) return true;
      scene.scenePreview(
        item,
        response => {
          if (response.data && response.data.url !== '') {
            axios
              .get(response.data.url)
              .then(text => {
                let decrypted = CryptoJS.DES.decrypt(text.data.replaceAll('\u0000', ''), key, { iv: iv });
                let imageData = {
                  sceneId: item.id,
                  hasImage: text ? true : false,
                  imageText: `data:image/jpeg;base64,${decrypted.toString(CryptoJS.enc.Base64)}`,
                  updateImage: true
                };
                tempSceneImages[item.id] = imageData;
                dispatch({ type: 'updateImage', payload: tempSceneImages  });
              })
              .catch(error => {
                console.error('Invalid request! Please try again.', error);
              });
          }
        },
        error => {
          console.error('error:', error);
        }
      );
    });
    setImages(tempSceneImages);
  };

  const _getFormatedName = (sceneName, index) => sceneName && sceneName.toLowerCase().replace(/ /g, '_') + '-' + index;

  const _duplicateScene = (oldSceneName, newSceneName) => {
    scene.copy(
      oldSceneName,
      newSceneName,
      success => {
        _listFiles();
      },
      error => {
        _showErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
      }
    );
  };

  const createDuplicateScene = fileName => {
    let modal = (
      <DuplicateSceneModal
        handleOk={newFileName => {
          _duplicateScene(fileName, newFileName.replace(/\s\s+/g, ' ').replace(' ', '-'));
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        fileName={fileName}
      />
    );
    setModal(modal);
  };

  const handleSceneExportClick = (e, sceneName) => {
    // document.getElementById(e.target.id).innerHTML = "Exporting";
    let SuccessModal = (
      <Modal
        message="Scene will be exported shortly."
        handleOk={() => {
          setModal(null);
          _listFiles();
        }}
        okButtonClass="btn-primary"
      />
    );

    scene.export(
      sceneName,
      response => {
        setModal(SuccessModal);
      },
      error => {
        // document.getElementById(e.target.id).innerHTML = "Export";
        _showErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
      }
    );
  };

  const handleSceneDownloadClick = (e, sceneName) => {
    // document.getElementById(e.target.id).innerHTML = "Downloading";
    scene.download(
      sceneName,
      data => {
        const link = document.createElement('a');
        link.href = data.url;
        link.setAttribute('download', sceneName);
        document.body.appendChild(link);
        link.click();
        // document.getElementById(e.target.id).innerHTML = "Download";
      },
      error => {
        // document.getElementById(e.target.id).innerHTML = "Download";
        _showErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
      }
    );
  };

  const _deleteModal = name => {
    scene.delete(
      name,
      response => {
        if (response.success) {
          _listFiles(false, currentPage);
        }
      },
      error => {
        alert(error.message);
      }
    );
  };
  const getDeleteConfirmation = name => {
    let modal = (
      <DeleteConfirmationModal
        message={'Are you sure you want to delete this element?'}
        handleOk={() => {
          _deleteModal(name);
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        fileName={name}
      />
    );
    setModal(modal);
  };

  const _showErrorModal = message => {
    setModal(
      <ErrorModal
        message={message}
        handleOk={() => {
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
      />
    );
  };

  const _showModal = data => {
    setModal(data);
  };

  const _hideModal = () => {
    setModal(null);
  };

  return (
    <PageWrapper modal={modal ? modal : <></>}>
      <div className={'tab-pane fade show active'} id="pills-scene-list" role="tabpanel" aria-labelledby="pills-scene-tab">
        <FileUploadWrapper _ShowModal={_showModal} _HideModal={_hideModal} successCallback={_listFiles} />

        <div className="spacer-xs-3"></div>

        <div className="table-wrapper table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="custom-scene-heading">
                  <Link to={'/add-scene'}>
                    <img src={plusPNG} />
                  </Link>
                  <span className="heading">Scene</span>
                </th>
                <th scope="col" className="heading">
                  Modified
                </th>
                <th scope="col" colSpan="3" className="heading">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.isLoading ? (
                <tr key="no_data">
                  <td colSpan="4" className="empty text-center">
                    Loading...
                  </td>
                </tr>
              ) : !data.isLoading && data.items && data.items.length === 0 ? (
                <tr key="no_data">
                  <td colSpan="4" className="empty text-center">
                    No Data Found.
                  </td>
                </tr>
              ) : (
                data.items  &&
                data.items.map((scene, index) => {
                  let status = scene.status ? scene.status.toLowerCase() : '';
                  if (moment(scene.last_updated_date).isAfter(scene.scene_export_date)) {
                    status = 'new';
                  }
                  let formatedName = _getFormatedName(scene.scene_name, index);
                  // let imageObj = images[scene.id];
                  return (
                    <tr key={index}>
                      <td className="custom-name" width="40%">
                        <div className="d-inline scene-preview-wapper">
                          <img
                            id={`scene-preview-${formatedName}`}
                            key={`scene-preview-${formatedName}`}
                            src={images[scene.id] ? images[scene.id].imageText : noImageIcon}
                            className="scene-preview zoom"
                            onError={e => {
                              e.target.src = noImageIcon;
                            }}
                          />
                        </div>
                        {scene.scene_name}
                      </td>
                      <td> {moment(scene.last_updated_date).format('MMM D, YYYY h:mm A')}</td>
                      <td className="td-fit-content" width="8%">
                        {status !== 'importing' && status !== 'importing failed' && (
                          <span className={`link `} onClick={() => createDuplicateScene(scene.scene_name)}>
                            <i className="icon-copy"></i>Copy
                          </span>
                        )}
                      </td>
                      <td className="td-fit-content" width="10%">
                        {status === 'exporting' || status === 'importing' ? (
                          <span className={`scene-status-label ${status}`}>
                            <img src={rotateIcon} width="18" className="h-100 me-1 rotate " />
                            <span className="custom-status text-capitalize text-exporting-importing">{status}</span>
                          </span>
                        ) : status === 'exporting failed' ? (
                          <span className={`scene-status-label ${status} `}>
                            <img src={warningIcon} width="16" className="h-100 me-1 mb-1" />
                            <span className="custom-status">Exporting failed {'  '}</span>
                            <a
                              className="text-retry"
                              onClick={e => {
                                handleSceneExportClick(e, scene.scene_name);
                              }}
                            >
                              Retry{' '}
                            </a>
                          </span>
                        ) : status === 'importing failed' ? (
                          <span className={`scene-status-label ${status} `}>
                            <img src={warningIcon} width="16" className="h-100 me-1 mb-1" />
                            <span className="custom-status">Importing failed </span>
                          </span>
                        ) : status === 'new' || status === 'imported' ? (
                          <button
                            id={`scene-export-${formatedName}`}
                            className={'btn btn-outline-blue rounded-pill '}
                            onClick={e => {
                              handleSceneExportClick(e, scene.scene_name);
                            }}
                          >
                            Export
                          </button>
                        ) : status === 'exported' ? (
                          <button
                            id={`scene-download-${formatedName}`}
                            className={'btn btn-outline-blue rounded-pill '}
                            onClick={e => {
                              handleSceneDownloadClick(e, scene.scene_name);
                            }}
                          >
                            Download
                          </button>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="td-fit-content" width="10%">
                        {status !== 'importing' && (
                          <button
                            className="btn btn-outline-danger rounded-pill"
                            onClick={() => {
                              getDeleteConfirmation(scene.scene_name);
                            }}
                          >
                            {' '}
                            Delete{' '}
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>

          <div className="spacer-xs-3"></div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={'page-item link ' + (currentPage <= 1 ? 'disabled' : '')} data-current-page={currentPage}>
                <span
                  className="page-link"
                  tabIndex="-1"
                  onClick={() => {
                    if(currentPage <= 1) {
											return true;
										}else{
											handlePreviousClick(currentPage);
										}
                  }}
                >
                  Previous
                </span>
              </li>
              {data.totalPages === 0 ? (
                <li className="page-item disabled">
                  <span className="page-link ">1</span>
                </li>
              ) : (
                [...Array(data.totalPages)].map((item, i) => {
                  return (
                    <li className={`page-item ${i + 1 === currentPage ? 'active' : 'disabled'}`} key={`page-${i}`} index={`page-${i}`} onClick={() => changeCurrentPage(i+1)}>
                      <span className="page-link ">{i +1}</span>
                    </li>
                  );
                })
              )}
              <li
                className={'page-item link ' + (currentPage >= data.totalPages ? 'disabled' : '')}
                data-current-page={currentPage}
                data-total-pages={data.totalPages}
                data-is-last-page={currentPage >= data.totalPages }
              >
                <span
                  className="page-link"
                  onClick={() => {
                    if(currentPage >= data.totalPages) {
											return true;
										}else{
											handleNextClick(currentPage);
										}
                  }}
                >
                  Next
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </PageWrapper>
  );
}
