import PageWrapper from './PageWrapper';

export default function TermsAndConditionsPage() {
  return (
    <PageWrapper hasInvoiceLayout={true}>
      <section className="inner licence">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card comman privacyPolicy">
                <h1>3DFrame Terms and Conditions</h1>
                <h1>Read about 3DFrame’s Terms and Conditions</h1>
                <h1>Introduction:</h1>
                <p>a. This document constitutes a temporary and non-exclusive license agreement between the User / licensee and VECTION or its distributor / reseller.</p>
                <p>
                  b. This contract describes the rights and obligations of the licensee and the conditions for using the software. The licensee will have to review the entire
                  agreement, including any additional documents (for example on the subject of privacy) and any conditions accessible through the links contained, since all the
                  conditions are important and together constitute the contract applicable to the licensee.
                </p>
                <p>
                  c. By accepting this agreement or using the software, the licensee accepts all its conditions and agrees to the transmission of information and data during
                  activation and when using the software in accordance with the Privacy Policy. If you do not accept these license terms and do not comply with them, you will not
                  be able to use the software or its functionalities.
                </p>
                <h1>General Terms</h1>
                <p>These General Terms apply to the User and to our Offers, and constitute a binding contract between the User and Vection.</p>
                <p>
                  <b>1. Acceptance</b>
                </p>
                <p>
                  By accepting these General Terms during the registration or subscription process, or by accessing or using our Offers, the User confirms the acceptance of these
                  General Terms and other applicable Terms, and gives his consent to become a party of this binding agreement. If the User does not accept, he has no right to
                  access or use the products.
                </p>
                <p>
                  The User agrees to these Terms on behalf of the company or other legal person for which he is acting (for example as an employee) or, in the absence of a company
                  or other legal person, on his own behalf as individual (in both cases, "the User"). The User represents and warrants that he has the right and authority (as well
                  as the legal capacity - for example, being of age) to act on behalf of the company to which he is part (if any) or on his own.
                </p>
                <p>Other capitalized words are defined in these Terms, including the "Definitions" section below.</p>
                <p>
                  <b>2. Right of withdrawal and refund</b>
                </p>
                <p>
                  For a limited period, following the purchase or renewal of a subscription by the User (the "Termination Term"), (i) if the User disputes any of the terms set
                  forth in these Terms, or (ii) if the User disputes the Vection purchase terms or the automatic renewal (if any) to be applied to the purchase or renewal of the
                  subscription, or (iii) if the User is dissatisfied (for any reason) with the Offer subscribed, the User may withdraw from the Offer and may benefit from a refund.
                </p>
                <p>
                  Regarding orders placed directly with Vection, Vection will provide a full refund of the User's subscription fee if the latter ceases to use the Offer and
                  exercises the right to withdraw from the Offer within the Withdrawal Deadline, as set out below. For orders placed through a reseller or other third party, please
                  see the relevant third party's withdrawal and refund policy.
                </p>
                <p>Event Subscription Period Deadline for Withdrawal (counted from the date of purchase or renewal)</p>
                <p>
                  New subscription, Renewal of an existing subscription, Adding users to an existing subscription, Aligning subscription or renewal billing dates Subscriptions with
                  a duration of more than one month (for example, annual
                </p>
                <p>14 days</p>
                <p>
                  <b>3. Account</b>
                </p>
                <p>3.1 Account Responsibility</p>
                <p>
                  In order to use a Vection product, the User may need an account. The User is responsible for anyone who obtains, accesses or uses the Offers through the User
                  himself or through his account (including your Authorized Users). This means, among other things, that the User is responsible for compliance with these Terms by
                  the Users Authorized by the User, including their use of their own accounts, as if each of the Users Authorized by the User were the User himself. In some cases,
                  Users Authorized by the User may be required to set up personal accounts or, otherwise, to adhere to the applicable terms in order to be able to obtain, access or
                  use the Offers, but such request does not affect the User's responsibility for the Users Authorized by the same User.
                </p>
                <p>
                  The User is also responsible for protecting his account and all activities related to it. This means (among other things) that the User (i) will guarantee that
                  only he himself will use the Offers associated with the User's account and (ii) will guarantee the protection and non-sharing of user IDs or passwords. If the
                  User suspects the unauthorized use of his account, please contact: support@vection-technologies.com
                </p>
                <p>
                  The User will guarantee that all information relating to his account (including all information that the User provides in relation to the User's registration to
                  any Offer) is and will continue to be true and complete.
                </p>
                <p>
                  <b>4. “You Own Your Work”</b>
                </p>
                <p>
                  The User will retain the ownership rights on files, projects, models, data sets, images, documents or similar material created by the User himself, and which the
                  User has sent or uploaded in connection with any Offer. The User authorizes the processing of all data sent / uploaded / shared by him through the software.
                </p>
                <p>
                  <b>5. Privacy</b>
                </p>
                <p>
                  Vection undertakes to protect the User's privacy and to inform the User of how Vection will use the personal data concerning him. Vection's privacy policy
                  establishes (i) the ways in which Vection collects, uses, stores and processes the User's personal data or concerning him and (ii) the ways in which the User can
                  request access to their personal data or the cancellation of the same or exercise their rights regarding privacy. The User declares to have read and understood
                  the Privacy Policy.
                </p>
                <p>
                  <b>6. Subscriptions</b>
                </p>
                <p>
                  Your Subscriptions may include Software or Web Services or a combination of both Software and Web Services. Your Subscriptions may also include Additional
                  Benefits.
                </p>
                <p>
                  <b>7. Software and Web Services</b>
                </p>
                <p>
                  If the User orders the Software by delivery of the Software, or if the User orders an Offer that includes a Software (for example, if a Web Service Offer requires
                  a Client Software), Vection will make available the download of the Software, through the User's account or through other electronic means, or through delivery to
                  the User by Vection or a third party authorized by Vection. For delivery of physical media or other tangible items of the Software, additional charges may apply.
                  Regardless of how the Software is delivered, Vection will not be liable for any loss or other liability incurred by the User or others, due to delay in delivery
                  or delivery to an incorrect address.
                </p>
                <p>
                  For each Offer consisting of the Software or Web Services that Vection makes available or delivers to the User, and which is subject to compliance with these
                  Terms and with all payment obligations, Vection guarantees the User for the period of the subscription, a non-exclusive, non-sub-licensable, and non-transferable
                  license to install and use the Software, solely (i) in accordance with the Offering Documentation and any other applicable Special Terms, if any, and (ii) all ''
                  within the scope of the User's subscription, including the number allowed, the Type of License, the Territory, and other specific characteristics relating to the
                  type and level that are specific to the User in light of the Offer to which it is subscriber. If Vection’s Offering Identification or other source of
                  identification of the Offering does not specify one or more of these elements, the license will be deemed a temporary Trial Version for You.The User may not
                  install, access or use (or permit the installation, access or use) of any Software other than that to which it is authorized under said license and these Terms,
                  and any other installation, access or use is not permitted.
                </p>
                <p>
                  During the subscription period, Vection may make available or deliver Updates or Enhancements for the Software.All Updates and Enhancements are subject to the
                  same license and other terms, as are the Software to which the Updates and Enhancements apply. The User is advised to promptly install and use the Updates and
                  Improvements that are made available during the subscription period.
                </p>
                <p>
                  Any Software (including any Updates and Enhancements) or Web Services that Vection makes available or delivers to the User is licensed for the limited period of
                  the subscription, is not sold, and the User cannot transfer or assign the license. Your subscription to a Web Service does not grant You a license for the
                  underlying Software used to provide the Web Service.
                </p>
                <p>
                  <b>8. Access and Use Of Offers</b>
                </p>
                <p>8.1 General Access and Terms of Use</p>
                <p>
                  Depending on the Offer, the User may request to have access to his account to activate, access or use (or to continue to access or use) the Offer.Only the User
                  may have access to or use an Offer.Access to and use of all Offers is subject (among other things) to periodic payment of the applicable amounts, including any
                  taxes and other costs, with reference to the Offers and compliance with these Terms.
                </p>
                <p>
                  Some Offers may cause the User's Electronic Devices to automatically access the Internet (on an intermittent or regular basis) - for example, to validate the
                  User's Subscription, to provide the User with access to services (including with third parties), or to download and install Updates and Improvements, all without
                  further communication to the User. The User consents to the aforementioned connection, to the validation of his Subscription and to the automatic download and
                  installation of Updates and Improvements. For some of the Offerings, the User may be able to change the Update or Enhancement settings of an Offer (this is not
                  available for some Offerings, including those for which Automatic Updates or Enhancements are required for operation or security. of the Offer).
                </p>
                <p>8.2 Use of Third Party Materials and Services</p>
                <p>
                  Vection may provide the User with content, designs, templates, data sets, project information, documents, libraries, audio, links, data, applications and other
                  software, services or similar materials of a third party (collectively "Third Party Materials / Services Parties ") in connection with the Offers. Each of such
                  Third Party Materials / Services will be subject to different terms, to be searched for in the same Third Party Materials / Services (for example, in the "About
                  Window" section or in a .txt file), on the web page indicated by Vection, or in the Special Terms or Documentation in which the Third Party Materials / Services
                  are provided (collectively "Third Party Terms"). If there is no Third Party Term, the User must (i) limit himself to the same terms as the Offer under which he
                  received the Third Party Materials / Services and (ii) solely in relation to the User's use of that Offer. The User assumes sole responsibility for determining,
                  obtaining and complying with all the Terms with Third Parties. Vection will have no liability, and makes no representations or warranties in relation to (a) any
                  of the Third Party Materials / Services or your use of such Third Party Materials / Services and (b) any Term of Third Parties or the User's compliance with such
                  Third Party Term.
                </p>
                <p>8.3 Use of User Content</p>
                <p>
                  With reference to the access or use of certain Offers by the User, or the provision to the User by Vection of certain services, the User may request to upload or
                  otherwise share the User Content. Vection staff will not use User Content unless (i) there is a User's request, or User's consent - for example when it comes to
                  providing the User with support, or addressing technical issues or other requests. , (ii) whether it is to provide and improve the Offers (including maintenance,
                  security, updating or other changes to the Offers), or (iii) for compliance with legal obligations, enforcement, controls or procedures (for example, in relation
                  to a subpoena). Generally, Vection does not review or review content posted under each Offer, website or service or that is otherwise made available to Vection.
                  Vection reserves the right, however, to check and review the User Content, and has the right to block or remove the content for any reason, including failure to
                  comply with these Terms (for example, because the content is illegal, offensive, or in violation of laws of any kind). When the User provides or makes the User
                  Content accessible, the User authorizes Vection and its designees to use, reproduce, modify, distribute, and make available the User Content in connection with
                  the provision of the Offers to the User and allowing Vection to comply with its obligations, and as otherwise provided for by these terms.
                </p>
                <p>
                  The User (a) is responsible for the entire User Content and for ensuring that the User Content and its use in relation to any Offer complies with all applicable
                  laws, regulations and these Terms, and (b) warrants that User Content will not infringe or misappropriate any proprietary or intellectual property right of any
                  person or that it will not violate any applicable law or regulation. Vection recommends the User to guarantee and protect the User Content using the appropriate
                  encryption and security technologies. The User acknowledges that the online services may be subject to sporadic disturbances or interruptions and that the User
                  may not be able to retrieve the User Content. Vection recommends that the User make a periodic backup of the User Content on their storage system. The User is
                  always responsible for the storage and maintenance of his own backup copies of the User Content. Aware of the obligation to make adequate backups of their data
                  and Content, the User exempts Vection from any liability in relation to any loss of data and Content, renouncing any right or claim against it.
                </p>
                <p>8.4 Collaboration and sharing of User Content</p>
                <p>
                  The software allows the User to collaborate with others, including the ability to share User Content or post it. If the User decides to share or publish the User
                  Content (whether through collaboration, sharing files with a project, via email, sharing a link, sharing a file with other applications or services, publishing on
                  a forum or gallery, or otherwise), then others (including, in some cases, the general public) may be able to download, use, sell, reproduce, modify, distribute,
                  make available, display, broadcast and communicate the User Content. Once the User has shared or posted User Content, suspending or terminating access will not
                  delete or disable access to any User Content that has previously been copied, transferred, or otherwise shared or published. Therefore, the User, in relation to
                  any use / transfer / sharing / publication of the Contents by third parties, exempts Vection from any liability, renouncing any right or claim against it. If the
                  User does not want others to have access or other rights, he must not share, publish or use other forms of collaboration of the Offers, and must set the User’s
                  permissions accordingly.
                </p>
                <p>
                  An Offer may include links to third parties offering services, software or other material complementary to that of such Offer. These links are provided as
                  convenient for the User. Vection does not monitor or control what third parties will do with User Content. The User is responsible for ensuring the appropriate
                  level of access to the User Content by the Third Parties. If the User authorizes the sharing with a third party of some of his information or of the User Content,
                  Vection may make the User information and the User Content available to that third party; However, Vection will have no responsibility for the actions of such
                  third party and all applicable terms and conditions, including those relating to privacy, are effective between the User and the third party.
                </p>
                <p>
                  <b>9. Trial Versions</b>
                </p>
                <p>
                  Vection may make available or deliver Offers (or features of an Offer) marked or granted as "not for resale", "free", "evaluation", "trial", "pre-sale", "beta" or
                  others. similar definitions (collectively, "Trials"). The User can download, install, access or use the Trial Versions only for the period and for the purposes of
                  the trial, as expressly permitted by Vection. Except as expressly provided in the Online Documentation or other Documentation for the Trial Version or the
                  applicable Special Terms, (i) the subscription period for the Trial Version will be limited to 15 days, (ii) the use by of the User will be limited to
                  non-commercial evaluation purposes, without any right to make the Trial Version available or distributable to third parties, and (iii) the use can only be made by
                  the User as a natural person or, if the User is a company or other legal person, by a specific employee. Notwithstanding everything contained in these Terms or
                  otherwise, (a) Vection makes no commitment with respect to the Trial Versions with respect to any functionality, function, level of service or data and makes no
                  warranties of any kind with respect to the Versions of Trial, (b) Vection may decide not to release Trial Versions generally or not to convert each Trial Version
                  into an offered product, and (c) Trial Versions may contain code that is not fully tested, including errors or defects which can cause complete data loss or
                  system failure. Trial Subscriptions do not include Subscription Benefits, and Vection reserves the right, without further notice, to terminate any Trial Version
                  at any time.
                </p>
                <p>
                  <b>10. Websites</b>
                </p>
                <p>
                  Apart from its Offers, Vection may provide information on its websites. The User agrees to use the Vection websites in accordance with the Website Terms of Use.
                </p>
                <p>
                  <b>11. Feedback</b>
                </p>
                <p>
                  You are under no obligation to provide Vection with any development ideas, suggestions or other feedback (collectively "Feedback"), whether in connection with the
                  Trial Version or otherwise, unless otherwise specified in the Special Terms of a 'Offering. If, however, the User provides any Feedback, the User grants Vection a
                  non-exclusive, transferable, irrevocable, global and free license (with the right to sub-license it) to make, use, sell, offer for sale, reproduce, modify,
                  distribute, make available, publicly display and perform, disclose and otherwise use the Feedback and any Offer that uses the Feedback.
                </p>
                <p>
                  <b>12. Restrictions on Use</b>
                </p>
                <p>12.1 The Offers</p>
                <p>
                  The Offers are intended solely to assist the User with their own projects, analyzes, simulations, estimates, tests and other activities, and do not represent a
                  substitute for the User's professional judgment or project, analysis, simulation, estimate, tests or other independent and user-specific activities, including
                  those relating to product stress, safety and utility. Due to the wide variety of potential applications for the Offers, they have not been designed or tested for
                  any specific use, and it is the User's responsibility to determine whether the use of an Offer is appropriate for the purpose set by the User. same. Vection will
                  not be responsible in any way for the results obtained through the use of the Offers, including any Outputs. You are responsible for your use of the Offers and
                  any results produced by the Offers, including any Outputs. The responsibility of the User includes, without any limitation, the determination of appropriate uses
                  for the Offers and the selection of the Offers, of other computer programs and materials intended to favor the results pursued by the User. The User is also
                  responsible for establishing adequate and independent procedures to test the reliability, accuracy, completeness, and compliance with applicable legal
                  requirements and all other characteristics of each Output, including, without limitation, all elements designed for the Assistance of Offers. The User further
                  acknowledges that the Offers and Outputs may not achieve the results that the User himself wishes to achieve through his own project, analysis, simulation,
                  estimate, test and other constraints.
                </p>
                <p>12.2 The Offers Are Not Scheduled for Storage of Sensitive Personal Data</p>
                <p>
                  The data storage functionality associated with Offerings is NOT suitable for archiving social security numbers, credit or debit card numbers, financial account
                  numbers, driving license numbers, medical or medical insurance information; data relating to personal characteristics or other personal information, such as
                  ethical origins, religious or philosophical beliefs, political orientations or opinions, genetic or biometric data, sexual orientation, or membership in trade
                  unions; or other information that may expose and put the individual at risk if improperly disclosed or used (collectively "Sensitive Personal Data"). Except as
                  expressly requested by Vection (for example, a credit card number to purchase a subscription), the User undertakes not to upload or otherwise make available in
                  the software or to Vection any Sensitive Personal Data, including any file containing Sensitive Personal Data.
                </p>
                <p>12.3 Acceptable Use of Offers</p>
                <p>
                  The User will access and use (and allow to access and use) the Offers exclusively in accordance with (and in compliance with) all applicable regulations. Except
                  as expressly authorized by these Terms, including any other Additional Agreement and Special Term, or as otherwise expressly provided in writing by Vection, the
                  User shall not:
                </p>
                <p>• Reproduce, modify, adapt, transfer, convert or create derivative works of any portion or of the Offering;</p>
                <p>
                  • Sub-license, distribute, transmit, sell, rent, lease, loan or otherwise make available any portion of and any Offer (including any functionality of each Offer)
                  to a third party, or provide any functionality of each Offer to a third party. part; And
                </p>
                <p>
                  • Access or use any Offer on or through the internet (other than as made available by Vection through the internet), any wide-area network (WAN) or other
                  non-local network; on or through any virtual private network (VPN); or on or through any virtual technology application, remote viewing technology, web-hosting,
                  time-sharing, software as a service, platform as a service, infrastructure as a service, cloud or other web based, hosted or similar service.
                </p>
                <p>In addition, the User must not:</p>
                <p>• Remove any copyright, trademark, confidentiality or other proprietary rights notices from the Offerings, Documentation or related material;</p>
                <p>
                  • Remove, disable or otherwise limit the effectiveness of any technical protection used by Vection to (i) manage, monitor, control or analyze the installation of,
                  access or use of each Offer or (ii) protect the rights intellectual property of Vection;
                </p>
                <p>• Publish, transmit, make available, or even just upload, using the Offers any information or material that is or may be:</p>
                <ul className="list-group list-group-numbered">
                  <li className="list-group-item border-0 pl-0"> false, libelous, defamatory, fraudulent or otherwise contrary to the law or unlawful;</li>
                  <li className="list-group-item border-0 pl-0">
                    threatening, harassing, degrading, hateful or intimidating or otherwise disrespecting the rights and dignity of others;
                  </li>
                  <li className="list-group-item border-0 pl-0"> obscene, indecent, pornographic or otherwise objectionable;</li>
                  <li className="list-group-item border-0 pl-0">
                    protected by copyright, trademark, design rights, industrial secret rights, publicity and privacy rights or any other proprietary rights, without the prior and
                    express written consent of the owner;
                  </li>
                  <li className="list-group-item border-0 pl-0">
                    a national or state secret, classified information, or any other information or material (including any photograph, design, plan or model) that is subject to
                    official confidential treatment;
                  </li>
                  <li className="list-group-item border-0 pl-0"> secret codes, countersigns, encrypted currency, passwords and other similar information;</li>
                  <li className="list-group-item border-0 pl-0">
                    advertising, spam, an offer to sell or buy any good or service, a "chain letter" or other form of solicitation; or
                  </li>
                  <li className="list-group-item border-0 pl-0">
                    any malware (such as a virus, worm virus, Trojan horse virus, time bomb or spyware) or other computer code, file or program that is or is potentially harmful or
                    invasive or that intends to damage or hijack the operation of, limit the use of, monitor the use of any hardware, software or equipment;{' '}
                  </li>
                </ul>
                <p></p>
                <p>• Use the Offers fraudulently or otherwise unlawfully or unlawfully, or with any purpose or effect that is fraudulent, unlawful or unlawful;</p>
                <p>
                  • Interfere with or disrupt the operation of any Offer or the servers or networks used to make Offers available, including hacking and defacing any portion of the
                  Offer;
                </p>
                <p>• Attempt to detect, analyze or test the vulnerability of any Offer or to breach or circumvent any security or authentication measures used for each Offer;</p>
                <p>
                  • Use each Offer as an archive for "remote loading" or as a "door" or "signpost" to other web pages or internet resources, whether internal or external to the
                  sites through which the Offers are provided;
                </p>
                <p>
                  • Impersonate another individual or other entity for the purpose of using their ID Vection or for any other purpose, or falsely or misrepresent the User's
                  affiliation with any other person or entity;
                </p>
                <p>
                  • Use the Offerings in connection with any inherently dangerous application, including any application that could cause death, personal injury, catastrophic
                  damage or sledgehammer destruction;
                </p>
                <p>
                  • Collect content or information, from and with an Offer, using automated procedures (such as robots, spider technology, site search / retrieval application or
                  other retrieval devices, index, "scrape," or "data mine");
                </p>
                <p>
                  • Use each Offering or the output of any Offering in connection with neural network formation or machine learning, deep learning, or artificial intelligence
                  systems or software;
                </p>
                <p>
                  • Disaggregate the components of each Offer for separate use from each other or on different electronic devices (with the exception of what may be expressly
                  authorized by Vection in writing); or
                </p>
                <p>
                  • Use or access Software made available as part of a Web Service separate from the applicable Web Service (except as may be expressly authorized by Vection in
                  writing).
                </p>

                <p>13. Vection Proprietary Rights</p>
                <p>
                  The User acknowledges and agrees that Vection and its suppliers will have full ownership of and all rights to (i) the Offerings, APIs, Metrics and other
                  information or materials provided or made available by Vection to the User and (ii) any copy of the above, or any other material or other information based on,
                  arising from or otherwise using the foregoing (including all rights to trade secrets, copyrights, trademarks, patents and everything else relating to intellectual
                  property or rights of ownership relating to all the foregoing). The structure and organization, related algorithms and other internal elements, protocols, data
                  structures and other external elements, and the source code of the Offerings and APIs constitute confidential and proprietary information of Vection, and the User
                  does not will disclose said information to third parties, or will use the aforementioned information for any purpose other than that required for ordinary access
                  and for the use of the Offers as required by these Terms, without the prior written consent of Vection. Furthermore, the User agrees not to access or attempt to
                  access the Offers through tools other than the interface provided or authorized by Vection. In addition, the User agrees not to engage in any decompilation,
                  disassembly, or other form of reverse engineering or otherwise not to attempt to discover, learn or study the structure of the organization, its algorithms and
                  internal elements, protocols, structure of the data and external elements, or of the source code of the Offers or the APIs, except as expressly permitted under
                  the applicable regulations and despite the opposite contractual prohibition. Vection may make available or provide access to other confidential and proprietary
                  information (both defined as such or intended as such in such circumstances). If the User receives this information, the User will not disclose it to any third
                  party, or will use this information for purposes other than those required for accessing and using the Offers, as provided for in these terms, without prior
                  notice. written consent of Vection.
                </p>

                <p>
                  The User is the owner only of those rights expressly recognized by him by these Terms (including any Additional Agreement or Special Term). All rights not
                  expressly granted are reserved in favor of Vection, and its licensors and suppliers; Vection and its licensors and suppliers expressly exclude (and the User
                  agrees not to assert) any other right.
                </p>

                <p>
                  The User agrees not to take any action or to authorize or encourage third parties to take any action (or collaborate with third parties in taking any action),
                  inconsistent with the foregoing.
                </p>

                <p>
                  <b>14. Limited Warranty, Disclaimers, Limitations of Liability</b>
                </p>
                <p>14.1 Limited Warranty</p>
                <p>
                  Vection guarantees that, for each paid subscription, starting from the date on which the Offer to which you subscribed becomes available to the User and for the
                  next 90 days or, if the subscription period is shorter, such shorter period ("Warranty Period"), the Offer will provide the general features and functionality
                  described in the End User Documentation for the relevant Offer. Vection’s entire obligation and liability, and the User’s sole and exclusive remedy, for Vection’s
                  breach of this warranty, will be Vection’s obligation, at its option, (i) to attempt to reasonably remedy the violation or (ii) to refund the amount received for
                  the subscription and terminate that subscription. The User must raise any warranty claims for any Offer exclusively during the Warranty Period under penalty of
                  forfeiture.
                </p>

                <p>14.2 Disclaimer of Liability</p>
                <p>
                  WITH THE EXCEPTION OF THE EXPRESS AND LIMITED WARRANTY PROVIDED IN THE ABOVE "Limited Warranty" SECTION, AND ANY OTHER EXPRESS WARRANTY PROVIDED AS SUCH IN ANY
                  ADDITIONAL AGREEMENT OR SPECIAL TERMS, TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW, (i) THE AS IS ("AS IS") AND (II) VECTION, YOUR LICENSEES AND
                  SUPPLIERS DO NOT PROVIDE, AND YOU DO NOT RECEIVE, ANY WARRANTY, REPRESENTATION, CONDITION OR UNDERTAKING OF ANY KIND, EXPRESS OR IMPLIED, WITH REFERENCE TO ANY
                  OFFER OR ANY OUTPUT, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A SPECIFIC USE, OR NON-INFRINGEMENT OR OTHER IMPLIED
                  WARRANTIES AND CONDITIONS UNDER THE STATUTE, OR ANY OTHER WARRANTY OR TRADE CONDITION BASED ON NEGOTIATION SECTOR. Any statements regarding the Offerings
                  (including any statements about their functionality and performance) or the Outputs, or other communications with the User, which are not covered by these Terms
                  or any other Additional Agreement or Special Terms, are limited to a mere purpose. informational and do not represent a warranty, representation, condition or
                  other commitment. Without limiting the general scope of the foregoing, Vection does not guarantee or undertake that (a) the Offers or Outputs, or access to them
                  or their use, are available, uninterrupted, error-free, secure, accurate, reliable or complete, (b) the Offers achieve specific performance, or availability
                  criteria, (c) the User Content is not lost or damaged, or (d) errors are corrected or other special requests for assistance are handled to satisfy the needs of
                  the User. Any reference to access, use, storage, or other “unlimited” items in reference to an Offer, will be subject to the technical limitations of the Offer
                  itself.
                </p>

                <p>14.3 Limitation of Liability</p>
                <p>
                  Neither Vection nor its licensors and suppliers will assume any liability (direct or indirect) for all incidental, special, indirect, consequential, sanctioning
                  damages; loss of profits or revenues; interruption of commercial activity or lack of use; costs of procuring fungible goods or services, or other hedging costs;
                  failure or anomalies in the Outputs; loss, damage or partial or total, temporary or definitive elimination of (or lack of elimination) of the data or of the User
                  Content. In any case, the liability of Vection, its licensors and its suppliers with respect to each Offer or Output will not exceed the amount paid or that could
                  be paid by the User for the Offer in relation to an annual period prior to the events or the circumstances that gave rise to the liability incurred.
                </p>

                <p>
                  The limitations of liability provided for in these Terms will apply to the maximum extent permitted by applicable law to any damage or other liability, however
                  caused and regardless of the assumptions of liability, whether based on contract, on wrong (including negligence and strict liability), on indemnity, recourse,
                  legislation or other, even if Vection has been advised of the possibility of liability and regardless of whether the limited remedies provided for in these terms
                  have failed to achieve their purpose.
                </p>

                <p>
                  The User acknowledges that the amount payable for the Offers is based in part on, and demonstrates the exclusions of warranties and limitations of liability in
                  these Terms, and that such exemptions and limitations are essential elements of the bargaining between the User and Vection.
                </p>
                <p>
                  Nothing in these Terms is intended to limit or exclude Vection’s liability for (i) death or personal injury caused by Vection’s willful misconduct or gross
                  negligence or (ii) damage or loss reported by You and caused by Vection’s fraud.
                </p>

                <p>14.4 Relationship with applicable legislation</p>
                <p>
                  Vection does not intend to limit the User’s warranties, or other rights and means of the User, or the liability of Vection for damages or losses to the extent
                  that such limitations are not permitted by law (such as warranties by statute, conditions, remedies , liabilities that cannot be excluded by applicable law).
                  Nothing in these Terms shall reduce the effect of warranties, Vection’s liability for damages or losses, or other terms that cannot be excluded or otherwise
                  modified under the law, despite a contractual restriction to the contrary. These Terms grant you specific legal rights, and you may also have other legal rights
                  that vary from jurisdiction to jurisdiction. For example, some jurisdictions do not allow the exclusion of implied warranties or the exclusion or limitation of
                  incidental or consequential damages, so much so that such provisions within these Terms may not apply to the User.
                </p>

                <p>
                  <b>15. Indemnification</b>
                </p>
                <p>
                  You will indemnify and hold harmless (and, at Vection’s request, defend) Vection from any loss, liability, expense (including legal fees to a reasonable extent)
                  suffered or incurred by Vection for any claim, legal action or proceeding (“Claim”) arising from or in relation to (i) User Content, (ii) use by the User
                  (including the User’s Authorized Users) of the Offers, including any Output or other results produced by such use and (iii ) violation by the User (including the
                  User’s Authorized Users) of these Terms (including Additional Agreements, Special Terms or other applicable terms).
                </p>

                <p>
                  <b>16. Termination</b>
                </p>
                <p>16.1 The User's Right to Termination</p>
                <p>
                  The User may terminate his subscription and these Terms if Vection is in material breach of these Terms (including any Additional Agreement, any Special Term or
                  any other applicable term) and fails to remedy such breach within 30 days of written notice of the breach.
                </p>

                <p>16.2 The Right of Vection to Termination</p>
                <p>
                  Vection may terminate any or all of the User's subscriptions or other Offers, these Terms and / or the User’s account, if (i) the User has no current paid
                  subscriptions, (ii) the User has failed to pay in time some of the sums (including fees and taxes) due with reference to any Offer or otherwise due to Vection,
                  (iii) the User (including any User Authorized by the User) has significantly violated these Terms (including any Additional Agreement, any Special Term or any
                  other applicable term), and has not remedied such breach of the 3-day period from the written notice of the breach, or (iv) the User is subject to bankruptcy or
                  insolvency proceedings, becomes insolvent, enters into an agreement with or involving its creditors (including an assignment for the benefit of the creditor) or
                  initiates liquidation proceedings. These Terms will automatically cease to be effective without any other warning or action by Vection if the User goes into
                  liquidation.
                </p>

                <p>16.3 Effect of Subscription Termination</p>
                <p>
                  Upon expiration or termination of a subscription or other Offer, for any reason, your rights with respect to that Offer will cease, including any related Software
                  licenses or subscription Benefits. At that time, the User will cease to access and use the Offer (including any access and use by the User’s Authorized Users) and
                  will uninstall all copies of the materials related to such Offer (including any Software, Documentation, API or other Vection material). In addition, at the
                  request of Vection, the User will destroy such copies or return them to Vection or the reseller from which the User purchased the Offer. The User will keep proof
                  that all copies have been returned or destroyed. In relation to the expiration or termination of a subscription, (i) for the convenience of the User in reference
                  to some Web Services, Vection will grant the User, upon written request of the latter, a short period (for example 3 days) to recover (if possible) the User
                  Content after the expiration or termination of the Web Services, if the User complies with these Terms and pays any applicable fees, (for example, the Vection
                  fees in force at that time and relating to the services professional for any assistance provided by Vection) and (ii) otherwise Vection may remove, without
                  notice, the User Content, in whole or in part, including backups and other copies thereof. For more information on retrieving content after expiration /
                  termination, please check the individual Web Service Offering. Such granting a short payback period for some Web Services, if available, does not release You from
                  your responsibility to maintain and protect complete copies of your User Content at all times.
                </p>

                <p>16.4 Effect of Termination of Terms</p>
                <p>
                  Upon any termination of these Terms for any reason, (i) the User’s account and its subscriptions, including those of Users Authorized by the User, will
                  immediately cease, (ii) the User will cease all access and use of each Offer (including the accesses and uses of Users Authorized by the User), and (iii) the
                  effects described above, relating to the termination of a subscription or other Offer, will apply. The payment obligations by the User, the ownership of the
                  User’s work (as described in “The User has the ownership of his own work” section), the obligations relating to APIs and User Development (including those in the
                  “API of Vection” section) and your indemnification obligations (including those in the “Indemnification” at section 19); the license relating to Feedback (in the
                  “Feedback” section); the rights of Vection and the obligations of the User with reference to proprietary rights (including the rights and obligations in
                  “Proprietary Rights of Vection” section); the disclaimers and limitations of liability (in “Limited Warranties, Disclaimers, Limitation of Liability” section);
                  applicable laws and dispute resolution provisions (in “Contracting Entities Vection, Applicable Law, and Dispute Resolution” section); and the User’s
                  responsibility for anyone who accesses or uses (or obtains) the Offers through the User or the User’s account (including the Users Authorized by the User)
                  (including the responsibility described in the “Account” section) will remain effective after termination for any reason.
                </p>

                <p>16.5 The Right of Vection to Suspend</p>
                <p>
                  If Vection believes in good faith that User Content or User conduct or lack of action (including the conduct or inaction of User Authorized Users) may (i)
                  represent a risk or otherwise have a negative impact on the Offers, systems or other users, (ii) represent or facilitate tampering by removing, disabling or other
                  limitations of the effectiveness of any technical protection (including any mechanism for the management, monitoring, control or analysis of installation of,
                  access to or use of each Offer or Vection’s intellectual property rights protections), (iii) subject Vection, any reseller or any other user to liability, or (iv)
                  fail to comply with these Terms (including any Additional Agreement, Special Term or Other applicable term), including failure to pay any amount due in respect of
                  each Offer, Vection has the right, but not the obligation, to disable or suspend the User’s access and the use of each Offer and the access and use of the User’s
                  Content. Unless Vection reasonably deems immediate action to be prudent, Vection will attempt to notify the User of the planned termination or suspension before
                  it becomes effective.
                </p>

                <p>
                  <b>17. Miscellaneous</b>
                </p>
                <p>17.1 Amendments to Offers</p>
                <p>
                  Vection reserves the right, from time to time (and the User acknowledges and accepts this right of Vection) (i) to modify or launch later versions of an Offer, or
                  discontinue an Offer and/or provide a replacement Offer in return, (ii) to modify or discontinue the Benefits, features and functionalities, or support services
                  or availability with respect to an Offer, whether in general or with reference to a specific geographic area or to a specific language, or (iii) to add or modify
                  license keys, authorizations, or other elements that control access to or use of the Offerings. Vection will undertake to inform the User of major changes to the
                  Offers.
                </p>

                <p>17.2 Changes to the Terms</p>
                <p>
                  To the maximum extent permitted by applicable law, Vection reserves the right to modify these Terms from time to time (and the User acknowledges this right of
                  Vection). Vection will undertake to notify the User of any material change to the Terms that may have a material adverse effect on the User (“Notice of Change”),
                  and the User will therefore have the opportunity to review such change. Except as otherwise provided in these Terms (including any Additional Agreement or Special
                  Term), if any such changes were to have a materially negative effect on the User and the User does not consent to the change, the User may refuse the modification
                  itself with notification to Vection of its refusal within 30 days of the Change Notice. If the User rejects a modification in such circumstances, (i) the User’s
                  access and use of each Offer affected by the modification will continue to be governed by the terms effective immediately prior to the modification (with the
                  exception of the hypothesis in the change was made for security, privacy, or regulatory compliance purposes) until (a) the end of the current subscription period
                  or other Offer, if applicable, or, (b) 180 days after the Change Notice, whichever is the earlier, and (ii) your rights relating to such Offers, including those
                  relating to subscription Benefits, will terminate. In the event of termination by the User, Vection (or its reseller) will reimburse the pro-rata portion of the
                  fees already paid and relating to the residual period of the User’s subscription, with reference to the Offer following the effectiveness of termination. Such
                  date will represent the end of the term of those Offers. If a subscription is renewed or extended, it will be subject to the then current Terms. The notices from
                  the User or from Vection will be provided as provided below, except that the User can provide notification of his refusal (in the 30-day period described above)
                  to the email address, or otherwise, specified in the Notice of Change.
                </p>

                <p>
                  Notwithstanding the foregoing, changes regarding the Privacy Policy, Special Terms, Subscription Types, Subscription Benefits or other policies will be managed as
                  described below.
                </p>

                <p>
                  The User acknowledges that his commitments with respect to Subscription Offers and Benefits are not conditional on the delivery of future features or
                  functionality (or oral or written statements about future features and functionality).
                </p>

                <p>17.3 Languages of the Terms; Interpretation</p>
                <p>
                  The English language version of these Terms will be the version used for the interpretation or understanding of these Terms, and any notices or communications
                  relating to these Terms will be provided in the English language. Any reference in these Terms to the words “days” refers to calendar days, unless otherwise
                  specified. The words “included” and “by way of example” or “for example”, and similar words, are not binding or exclusive and will be treated as if followed by
                  “without limitation”, whether or not they are included words. The sections and other titles are used for simplification purposes only and are not used to
                  interpret the meaning of any term. Each right or remedy provided in these Terms is cumulative and must be added to the others, and should not be construed as
                  replacing any other right or remedy available under applicable law.
                </p>

                <p>17.4 Governing Law and Dispute Resolution</p>
                <p>
                  In the case of a natural person consumer, where the User’s residence is located, the following Terms are understood to be valid between the User and the Vection
                  Subject indicated below. The applicable law for these Terms, including any rights, obligations and claims of the parties, will be specified below. Likewise, any
                  dispute, claim or dispute arising out of or in relation to these Terms, including any violation, execution, termination or withdrawal, application, interpretation
                  or validity of these Terms (whether arising out of contract, wrong, including negligence and strict liability , competition rules or otherwise), and including
                  determining the purpose or applicability of the dispute resolution provisions of these Terms, will be determined in accordance with the law, place and dispute
                  resolution process specified below.
                </p>

                <p>
                  If the User has any dispute with respect to an Offer or which has arisen in connection with these Terms (including any Additional Agreement, the Vection Statement
                  of Confidentiality or any other applicable term), the User will first attempt to resolve the informal dispute with Vection, providing notice of the dispute
                  (including a description of the dispute and related documents) in the manner described below for the Notices and cooperation with Vection, in order to amicably
                  resolve the matter. If the dispute is not resolved within 30 days of receipt of the notice, both the User and Vection may submit a formal request to the competent
                  court for the resolution of disputes, as described above (to be defined on the basis of the seat of main business of Webex or, if the User is a consumer
                  individual, based on the place of residence).
                </p>

                <p>Notwithstanding the foregoing, Vection may appeal to any jurisdiction or court for injunction proceedings and other legal measures (and related thereto).</p>

                <p>17.5 Compliance</p>
                <p>
                  Vection has the right to verify the installation, access and use of all Offers by the User and the User’s Authorized Users. As part of this verification, Vection
                  or its authorized representative has the right, with 15 days notice, to inspect the User’s data, systems and facilities, including machine IDs, serial numbers,
                  Vection ID and other related information, at the User’s location using an approved Vection verification tool. In addition to Vection’s right to perform a
                  verification at the User’s premises, within 15 days of the request for such verification, the User will provide Vection with a report, through an approved
                  verification tool of Vection, regarding the installation, all access and use of the Offers by the User and his Authorized Users, including machine IDs, serial
                  numbers, Vection IDs and other related information. If Vection believes that User’s installation, access or use does not comply with these Terms (including
                  Additional Agreements, Special Terms or other applicable terms), the User will immediately purchase new subscriptions to remedy the non-compliance and will bear
                  the reasonable costs of Vection’s audit. Vection reserves the right to pursue any other remedial action required by law or equity.
                </p>

                <p>17.6 Force majeure</p>
                <p>
                  Neither party will be liable or have any liability for any delay or failure to perform due to unforeseen circumstances or for reasons beyond any of its reasonable
                  control, including situations of force majeure, earthquake, fire, flood, sanctions, embargoes, strikes, lockouts, or other trade union disturbances, civil unrest,
                  bankruptcies, unavailability or delays of suppliers or licensees, pandemics, riots, terrorist or otherwise malicious or criminal acts, wars, breakdown or
                  interruption of the internet or internet connections of third parties or their infrastructure, power outages, acts of civil and military authorities and adverse
                  weather conditions (“Force Majeure”). The affected party will give prompt written notice (when possible) of the non-performance and will make reasonable efforts
                  to limit the resulting delay in its performance.
                </p>

                <p>17.7 Transfer</p>
                <p>
                  The User cannot assign or otherwise transfer these Terms, or the User’s rights or the obligations deriving from these Terms (whether they arise from enforcement
                  of the law or otherwise) without the prior written consent of Vection, and Vection may withdraw by these Terms (including any User’s rights under these Terms) if
                  the User has been acquired by, or will be controlled by, any other person or entity (whether by means of a share purchase, merger or other transaction) without
                  consent written by Vection. Vection may assign or otherwise transfer these Terms (without the User’s consent or notice to the User) as part of a reorganization,
                  merger, sale of assets, or other transactions involving all, or some portions of the Offers, or relating to the activity.
                </p>

                <p>17.8 Exclusion of Waiver</p>
                <p>
                  If and to the extent that any provision of these Terms is not applicable under current law, (i) such provision will be deemed to be amended to the extent
                  reasonably necessary to ensure compliance with applicable law, but at the same time ensuring the maximum effectiveness of the will of the parties set forth in
                  these Terms, and (ii) that provision will be ineffective under the jurisdiction that deems it unenforceable without this entailing consequences in other
                  jurisdictions.
                </p>

                <p>17.9 Communications</p>
                <p>
                  Any communication from the User to Vection will be sent by post or by shipping service to Vection Australia Pty Ltd, Level 4, Building C, 355 Scarborough Beach
                  Road, Osborne Park WA 6017.
                </p>
                <p>
                  Except as expressly set forth in these Terms (including any Additional Agreement or Special Term), any notice provided by Vection to the User will be communicated
                  (i) by email to the registered email address associated with the User’s account (ii) with publication on the User’s account (iii) by means of publication within
                  an Offer (for example, through a notification function internal to the Offer or in the access notification), (iv) by post or by means of a shipping service to the
                  address associated with the User's account, or (v) in any other way that appears to be reasonable to Vection which results in notifications to the User. The
                  notices from Vection to the User in case of communications via email, will be effective the day after sending. The User declares that he wishes to receive
                  communications with legal value via registered mail/certified email at the address indicated in the profile.
                </p>

                <p>17.10 Overall Agreement</p>
                <p>
                  These Terms, including the Privacy Policy, any Additional Agreement and any Special Term (which are deemed to be included in these Terms), constitute the overall
                  agreement between the User and Vection (and include and prevail over any agreement, discussion, communication, representation, guarantee, publicity or previous or
                  contemporary understanding) with reference to the subject deduced in question.
                </p>

                <p>
                  <b>18 Definitions</b>
                </p>

                <p>
                  Users means (i) the User (if the User is a natural person) and (ii) specifically identified subjects (such as employees of the User, consultants and contracting
                  parties and other persons who can access and use an Offer for the benefit of the User ) for which the User has purchased a subscription to an Offer. If any of the
                  Offers allows the User to designate Authorized Users for the Offer, the User will be responsible for providing notice thereof, and for obtaining the consent of
                  each of the Authorized Users, in relation to the application of these Terms in regarding their access and use of said Offer, before their actual access and use of
                  the same.
                </p>

                <p>Vection means Vection Australia Pty Ltd. </p>

                <p>
                  Benefits means each benefit that Vection makes available to the User or to an Authorized User of yours. Benefits are generally classified according to the level
                  or type of Offerings the User subscribes to. These Benefits may include access to Updates and Enhancements, Previous Version Rights, Additional Software and Web
                  Services, Trials, APIs, Global Travel Rights, Technical Support, Training, Online Seminars, Forums, Events, Galleries, Newsletters and data usage. Benefits may
                  also include account related benefits, such as single sign-on and User profile management, security settings, linked accounts and preferences.
                </p>

                <p>
                  Confidential Information means information not generally disclosed to the public that is (i) made available or disclosed in writing by a Disclosing Party to a
                  Receiving Party, and (ii) defined as Confidential in writing by the Disclosing Party. Vection Confidential Information also includes non-public aspects of (i)
                  certain Offerings and related production plans, technology and other technical information and (ii) business dealings. However, Confidential Information does not
                  include (a) that information which (1) can generally be disclosed to the public without breaching any obligation of the Disclosing Party; (2) they were aware of
                  the Receiving Party prior to the disclosure by the Disclosing Party without the breach of any obligation (and without duty of confidentiality) on the part of the
                  Disclosing Party; (3) are received from third parties without the breach of any obligation (and without duty of confidentiality) on the part of the Disclosing
                  Party; or (4) have been developed independently by the Receiving Party; (b) the User Content that has been sent by the User himself, or to which the User has
                  granted access, to a third party through an Offer or (c) any Feedback.
                </p>

                <p>
                  Customer Information Form means a model prepared by, or on behalf of the User and submitted to Vection (or its reseller), directly and indirectly, with reference
                  to the User's account, a subscription or other Offer.
                </p>

                <p>
                  Documentation means all documentation of each end user (including online, paper or other documentation) and any technical and legal requirements relating to the
                  Offer.
                </p>

                <p>
                  Electronic Devices means (i) computers (whether desktop, laptop or tablet); (ii) virtual machines that cannot be consulted through the network connection; and
                  (iii) mobile devices.
                </p>

                <p>License Type means the type of license specified by Vection for each subscription (for example, single-user or multi-user license).</p>

                <p>Offers means the Software, Web Services and other Benefits provided by Vection, as well as any subscription related to such items. </p>

                <p>
                  Output means any result, work product, design, prototype or other item created or generated through the use of an Offer, including all products, parts or services
                  based on such results, work products, designs, prototypes and other items, or use the same.
                </p>

                <p>
                  Software means any software or similar item, including also any module, component, feature and function, which is made available by Vection, and whether or not it
                  is provided as part of a subscription and whether or not it is provided free of charge. The Software includes Updates and Enhancements.
                </p>

                <p>
                  Terms (including the definition "these Terms") means these General Terms and other terms referring to them, including the Special Terms, Vection’s Privacy Policy
                  and Additional Agreements, if any, together with any other applicable terms.
                </p>

                <p>
                  Territory means the country or jurisdiction in which the User purchased their subscription. Vection may indicate the appropriate Territory in the Offering
                  Identification.
                </p>

                <p>Trial Versions means the same concept already defined in the “Trial Versions” section.</p>

                <p>
                  Updates means the security fixes, hot fixes, patches, and other updates (including new features, new functions and other changes issued among the Enhancements) if
                  and when made available to the User by Vection and defined by the latter as actual updates.
                </p>

                <p>
                  Improvements means new versions of the Offers, or additions or additional products to individual Offers, if and when made available to the User by Vection, and
                  hence it is decided that they are improvements.
                </p>

                <p>
                  Web Service means a web-based or cloud-based service made available by Vection whether or not it is provided as part of a subscription and whether or not it is
                  provided for a fee.
                </p>

                <p>
                  User Content means (i) any file, project, model, data set, image, document or similar material, provided or uploaded in each Offer by the User or by the Users
                  Authorized by the User and (ii) the specific outputs of the User generated by the use of each Offer on the basis of the raw data and information possessed by the
                  User.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
}
