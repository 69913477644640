import { _postRequest } from './_apiReq';

export const user = {
  getInfo(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/get-user-info',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not fetch user info. Try again!'
    );
  },

  update(user, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('name', user.name);
    formData.append('username', user.username);
    formData.append('email', user.email);
    formData.append('country', user.country);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/update-user-info',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not fetch user info. Try again!'
    );
  },
  getMemoryUsage(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/user-memory-info',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not fetch memory usage info. Try again!'
    );
  }
};
