import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import addFileSVG from '../../images/add-file.svg';
import { _parseSceneXML } from '../../utils';
import axios from 'axios';
import { _parseAdminXML } from '../../utils';
import FrameSUpload from '../common/FrameSUpload';
import ErrorUploadFileRow from '../common/ErrorUploadFileRow';
import ErrorModal from '../modals/ErrorModal';
import { scene } from '../../services/scene';
import Modal from '../modals/Modal';

export default function ImportingScene() {
    const [setupfile, setSetupfile] = useState(null);
    const [isUploading, setIsUploading] = useState(null);
    const [modal, setModal] = useState(null);
    const [errorFile, setErrorFile] = useState(null);
    const [showUploader, setShowUploader] = useState(false);
    const navigate = useNavigate();
    let fileInputRef = useRef();

    const handleUploadChange = e => {
        e.preventDefault();
        setSetupfile(null);
        setErrorFile(null);
        let file = e.target && e.target.files[0];

        if (!isUploading && file) {
            setIsUploading(true);
            fileInputRef.current.value = null;
            scene.getPresignUrl(
                file['name'],
                data => {
                    const resultObj = data.result;
                    if (resultObj.response) {
                        const CancelToken = axios.CancelToken;
                        const CancelTokenSource = CancelToken.source();
                        setSetupfile({ file: file, 'CancelTokenSource': CancelTokenSource, presignedUrl: resultObj.response });
                        setIsUploading(false);
                        setShowUploader(true);
                    } else {
                        setIsUploading(false);
                        setErrorFile({ name: file['name'], error: resultObj.error });
                    }
                },
                error => {
                    _memberOnlyErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
                    setIsUploading(false);
                }
            );
        }
    };

    const _successCallback = () => {
        scene.importDBSave(
            setupfile.file.name,
            response => {
                setModal(SuccessModal);
            },
            error => {
                _memberOnlyErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
            }
        );
    };

    const _memberOnlyErrorModal = message => {
        setModal(
            <ErrorModal
                message={message}
                handleOk={() => {
                    setModal(null);
                }}
                handleCancel={() => {
                    setModal(null);
                }}
            />
        );
    };

    let SuccessModal = (
        <Modal
            message="Scene will be imported shortly."
            handleOk={() => {
                setModal(null);
                navigate('/scene-list');
            }}
            okButtonClass="btn-primary"
        />
    );

    return (
        <div className="add-document">
            <div className="document-drop-wrapper">
                <form encType="multipart/form-data" className="form-narrow" method="post" id="fileForm" onSubmit={(e) => e.preventDefault()}>
                    <div
                        className="entry-icon mb-2 link"
                        onClick={() => {
                            fileInputRef && fileInputRef.current.click();
                        }}

                    >
                        <img src={addFileSVG} />

                    </div>
                    <span
                        className="btn btn-primary"
                        onClick={() => {
                            fileInputRef && fileInputRef.current.click();
                        }}
                    >
                        <i className="icon-add"></i>Import 3DF
                    </span>

                    <input type="file" onChange={handleUploadChange} ref={fileInputRef} />
                </form>
            </div>
            {showUploader && setupfile && <FrameSUpload key={'fileuploadRow_1'} {...setupfile} _uploadCompleteCallback={() => { }} _successCallback={_successCallback} />}
            {errorFile && <ErrorUploadFileRow key={'fileuploadRow_error'} {...errorFile} />}
        </div>
    );
}
