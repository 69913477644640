import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const { pathname } = useLocation();
  return (
    <ul className="nav nav-pills" id="pills-tab" role="tablist">
      <li className="nav-item" role="presentation">
        <Link to={'/scene-list'} className={'nav-link ' + (pathname.indexOf('scene') >= 0 ? 'active' : '')}>
          Scenes
        </Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link to={'/model-manager'} className={'nav-link ' + (pathname === '/model-manager' ? 'active' : '')}>
          Models
        </Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link to={'/hdri-manager'} className={'nav-link ' + (pathname === '/hdri-manager' ? 'active' : '')}>
          HDRIs
        </Link>
      </li>
      <li className="nav-item" role="presentation">
        <Link to={'/file-manager'} className={'nav-link ' + (pathname === '/file-manager' ? 'active' : '')}>
          Multimedia Files
        </Link>
      </li>
      {localStorage.getItem('role') && localStorage.getItem('role') === 'admin' && (
        <li className="nav-item" role="presentation">
          <Link to={'/frame-files'} className={'nav-link ' + (pathname === '/frame-files' ? 'active' : '')}>
            3D Frame Files
          </Link>
        </li>
      )}
    </ul>
  );
}
