import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper';
import { stripeSubscription } from '../../services/stripeSubscription';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function InvoiceListPage() {
  const [invoiceLoading, setIvoiceLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    stripeSubscription.getInvoiceList(
      response => {
        setIvoiceLoading(false);
        setInvoices(response);
      },
      error => {
        setIvoiceLoading(false);
        console.log('error', error);
      }
    );
  }, []);

  return (
    <PageWrapper hasInvoiceLayout={true}>
      <section className="inner licence">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card comman">
                <h1>Invoice</h1>
                <div className="table-wrapper table-responsive">
                  <table className="table invoices-table">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">Amount Due</th>
                        <th scope="col">Created</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceLoading ? (
                        <tr>
                          <td className="text-center" colSpan="6">
                            Loading...
                          </td>
                        </tr>
                      ) : !invoiceLoading && !invoices.length ? (
                        <tr>
                          <td className="text-center" colSpan="6">
                            No Data found
                          </td>
                        </tr>
                      ) : (
                        invoices &&
                        invoices.map((invoice, index) => {
                          return (
                            <tr key={'invoice-' + index}>
                              <td>{index + 1}</td>
                              <td width="36%">{invoice.customer_name}</td>
                              <td>{invoice.amount_paid}</td>
                              <td>{invoice.amount_due}</td>
                              <td>{moment.unix(invoice.created).format('DD MMMM YYYY ')}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Link to={'/invoices/' + invoice.id} className="btn btn-outline-blue rounded-pill" href={invoice.invoice_pdf}>
                                    View
                                  </Link>
                                  &nbsp;&nbsp;
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
}
