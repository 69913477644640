import React, { useState, useEffect, useRef, useReducer } from 'react';
import axios from 'axios';
import { admin } from '../../services/admin';
import { _parseAdminXML } from '../../utils';
import upload3SVG from '../../images/upload3.svg';
import PageWrapper from '../PageWrapper';
import { Navigate } from 'react-router-dom';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import ErrorModal from '../modals/ErrorModal';
import uploadReducer from '../../reducers/uploadReducer';
import { framesFile } from '../../services/framesFile';
import FileUpload from './FileUpload';


const versionInitialState = [];

function versionReducer(state, action) {
  const data = [...state]
  switch (action.type) {
    case "set":
      return [...action.data]
    case "set-version":
      data[action.index].version = action.version
      return [...data]
    case "set-osx_link":
      data[action.index].osx_link = action.osx_link
      return [...data]
    case "set-win_link":
      data[action.index].win_link = action.win_link
      return [...data]
    default:
      throw new Error();
  }
}

export default function Index() {
  const [uploadFiles, uploadFilesDispatch] = useReducer(uploadReducer, []);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [isFilesReqSent, setIsFilesReqSent] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [versionUpdateIndex, setVersionUpdateIndex] = useState(-1);
  
  const [versionUpdateMessage, setVersionUpdateMessage] = useState(null);
  const [versionInfo, setVersionInfo] = useState(null);
  const [newVersion, setNewVersion] = useState('');
  const [newUrlForMac, setNewUrlForMac] = useState('');
  const [newUrlForWindows, setNewUrlForWindows] = useState('');

  const [modal, setModal] = useState(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [tokens, setTokens] = useState(['']);
  const [currentPage, setCurrentPage] = useState(0);
  const [versionState, versionDispatch] = useReducer(versionReducer, versionInitialState);

  let fileInputRef = useRef();

  useEffect(() => {

    if (!isFilesReqSent) {
      setIsFilesReqSent(true);
      _listFiles(0, true);
    }
  }, []);

  useEffect(() => {
    if (!versionInfo) {
      _getVersionDetails();
    }
  }, []);

  useEffect(() => {
    _VersionList()
  }, []);

  
  const _getVersionDetails = () => {
    admin.getVersion(
      result => {
        setVersionInfo({ ...result });
        setNewVersion(result.version);
        // setNewAPIVersion(result.API_version);
        setNewUrlForMac(result.osx_link);
        setNewUrlForWindows(result.win_link);
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const _VersionList = () => {
    admin.versionList(
      result => {
        versionDispatch({ type: "set", data: result })
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handlePreviousClick = () => {
    setIsLastPage(false);
    let page = currentPage;
    setCurrentPage(page - 1);
    _listFiles(page - 1);
  };

  const handleNextClick = () => {
    let page = currentPage;
    setCurrentPage(page + 1);
    _listFiles(page + 1, true);
  };

  const _setPageToken = async (currentPage, nextPageToken, contToken) => {
    if (!nextPageToken) {
      setIsLastPage(true);
    }
    let tempTokens = tokens;
    tempTokens[currentPage + 1] = nextPageToken ? nextPageToken : contToken;
    setTokens([...tempTokens]);
  };

  const _listFiles = async (page = 0, isNextPage = false) => {
    setIsLoading(true);
    setFiles(null);
    admin.getFilesList(
      tokens[page],
      result => {
        let { fileData, nextContinuationToken, continuationToken } = _parseAdminXML({ ...result });
        if (isNextPage) {
          _setPageToken(page, nextContinuationToken, continuationToken);
        }
        setFiles(fileData);
        setIsLoading(false);
      },
      error => {
        setIsLoading(false);
        console.log('error:', error);
      }
    );
  };

  // const _successCallback = () => {
  //   setSetupfile(null);
  //   _listFiles(currentPage);
  // };

  const _memberOnlyErrorModal = message => {
    setModal(
      <ErrorModal
        message={message}
        handleOk={() => {
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
      />
    );
  };

  const handleUploadChange = e => {
    if (e.target.files) {
      let totalItems = Object.keys(uploadFiles).length;
      let temp = [];
      let file = e.target && e.target.files[0];
      framesFile.getPresignUrl(
        file['name'],
        (res) => {
          const CancelToken = axios.CancelToken;
          const CancelTokenSource = CancelToken.source();
          temp.push({
            ...file,
            fileName: file['name'],
            CancelTokenSource: CancelTokenSource,
            file: file,
            fileId: totalItems++, 
            presignedUrl: res.url
          });
          uploadFilesDispatch({ type: 'added', payload: temp });
        },
        error => {
          console.log('error:', error);
        }
      );
    }
  };

  
  const successCallback = () => {
    // getDatalList();
    // setSetupfile(null);
    _listFiles(currentPage);
  };

  const removeFile = fileId => {
    uploadFilesDispatch({ type: 'remove', payload: { fileId } });
  };

  // const handleVersionSubmit = e => {
  //   e.preventDefault();
  //   let versionData = {
  //     ...versionInfo,
  //     version: newVersion,
  //     // API_version: newAPIVersion,
  //     osx_link: newUrlForMac,
  //     win_link: newUrlForWindows
  //   };
  //   admin.updateVersion(
  //     versionData,
  //     result => {
  //       setVersionUpdateMessage('Version updated successfully!');
  //       _getVersionDetails();
  //       setTimeout(() => {
  //         setVersionUpdateMessage(null);
  //       }, 5000);
  //       setVersionInfo({ ...result });
  //     },
  //     error => {
  //       alert('Error. Could not update version details.');
  //       console.log('error:', error);
  //     }
  //   );
  // };
  
  const handleVersionSubmit = (versionIndex) => {
    let versionData = {
      id: versionState[versionIndex].id,
      product: versionState[versionIndex].product,
      version: versionState[versionIndex].version,
      osx_link: versionState[versionIndex].osx_link,
      win_link: versionState[versionIndex].win_link,
    };
    admin.updateVersion(
      versionData,
      result => {
        setVersionUpdateIndex(versionIndex);
        _VersionList();
        setTimeout(() => {
          setVersionUpdateIndex(-1);
        }, 5000);
      },
      error => {
        alert('Error. Could not update version details.');
        console.log('error:', error);
      }
    );
  };

  const _deleteModal = name => {
    setShowLoader(true);
    admin.delete(
      name,
      response => {
        if (response.success) {
          _listFiles(currentPage);
        } else {
          setShowLoader(false);
        }
      },
      error => {
        setShowLoader(false);
        alert(error.message);
      }
    );
  };

  const getDeleteConfirmation = name => {
    let modal = (
      <DeleteConfirmationModal
        message={'Are you sure you want to delete this element?'}
        handleOk={() => {
          _deleteModal(name);
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        fileName={name}
      />
    );
    setModal(modal);
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  if (localStorage.getItem('role') && localStorage.getItem('role') === 'admin') {
    return (
      <PageWrapper modal={modal}>
        <div
          className={'tab-pane fade show ' + (window.location.pathname === '/frame-files' ? 'active' : '')}
          id="pills-models"
          role="tabpanel"
          aria-labelledby="pills-models-tab"
        >
          <div className="add-document">
          <div className="document-drop-wrapper">
            <form id="fileForm" encType="multipart/form-data" className="form-narrow" method="post" onSubmit={e => e.preventDefault()}>
              <div
                className="entry-icon mb-2 link"
                onClick={() => {
                  fileInputRef && fileInputRef.current.click();
                }}
              >
                <img src={upload3SVG} />
                <p>Drag & drop files here or click to browse</p>
              </div>

              <span
                className="btn btn-primary"
                onClick={() => {
                  fileInputRef && fileInputRef.current.click();
                }}
              >
                <i className="icon-upload2 fs-xs-20"></i>Upload
              </span>
              <input type="file" onChange={handleUploadChange} ref={fileInputRef} />
            </form>
          </div>

          {Object.keys(uploadFiles).map(index => {
            let file = uploadFiles[index];
            return (
              <FileUpload
                file={file}
                key={'file-' +  file.fileName } 
                removeFile={() => {
                  removeFile(file.fileId);
                }}
                successCallback={successCallback}
              />
            );
          })}
        </div>
         
          <div className="spacer-xs-3"></div>
          <div className="table-wrapper table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" width="65%">
                    Name
                  </th>
                  <th scope="col" width="15%">
                    Size
                  </th>
                  <th scope="col" width="20%">
                    Modified
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr key="no_data">
                    <td colSpan="4" className="empty">
                      Loading...
                    </td>
                  </tr>
                ) : files && files.length === 0 ? (
                  <tr key="no_data">
                    <td colSpan="4" className="empty">
                      There are no saved files
                    </td>
                  </tr>
                ) : (
                  files &&
                  files.map((file, index) => {
                    return (
                      <tr key={'data_' + (index + 1)}>
                        <td width="65%">{process.env.REACT_APP_FRAMES_PREFIX + file.name}</td>
                        <td width="15%">{file.size}</td>
                        <td width="20%">{file.lastModified}</td>
                        <td>
                          <span
                            className="btn btn-outline-danger rounded-pill"
                            onClick={() => {
                              getDeleteConfirmation(file.name);
                            }}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="spacer-xs-3"></div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={'page-item ' + (currentPage < 1 ? 'disabled' : '')}>
                <span
                  className="page-link"
                  tabIndex="-1"
                  onClick={() => {
                    if (files && files.length) handlePreviousClick();
                  }}
                >
                  Previous
                </span>
              </li>
              <li className="page-item disabled">
                <span className="page-link ">{currentPage + 1}</span>
              </li>
              <li className={'page-item ' + (isLastPage ? 'disabled' : '')}>
                <span
                  className="page-link"
                  onClick={() => {
                    if (files && files.length) handleNextClick();
                  }}
                >
                  Next
                </span>
              </li>
            </ul>
          </nav>

          <div className="spacer-xs-3"></div>
          <div className="frame-update">
            <h4 className="entry-heading">3D Frame Version Update</h4>
            <p>Current Version: {versionInfo ? versionInfo.version : ''}</p>
            <p className=" text-success">{versionUpdateMessage}</p>
            <form onSubmit={handleVersionSubmit} className="form-narrow" method="post" id="frames-version-form">
              <div className="row mb-4 form-group align-items-center">
                <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                  Version
                </label>
                <div className="width-lg-80">
                  <input type="text" className="form-control" required={true} value={newVersion} onChange={e => setNewVersion(e.target.value)} />
                </div>
              </div>
              <div className="row mb-4 form-group align-items-center">
                <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                  3D Frame Url for Mac:
                </label>
                <div className="width-lg-80">
                  <input type="text" className="form-control" required={true} value={newUrlForMac} onChange={e => setNewUrlForMac(e.target.value)} />
                </div>
              </div>
              <div className="row form-group mb-4">
                <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                  FrameS url for Windows:
                </label>
                <div className="width-lg-80">
                  <input type="text" className="form-control" required={true} value={newUrlForWindows} onChange={e => setNewUrlForWindows(e.target.value)} />
                </div>
              </div>
              <div className="row form-group">
                <label htmlFor="staticEmail" className="width-lg-20 col-form-label"></label>
                <div className="width-lg-80">
                  <button className="btn btn-primary px-5 py-3" type="submit">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>

          {versionState.map((version, versionIndex) => {
            return <>
              <div className="spacer-xs-3"></div>
              <div className="frame-update">
                <h4 className="entry-heading">{Capitalize(version.product)} Version Update</h4>
                <p>Current Version: {version ? version.version : ''}</p>
                <p className=" text-success">{versionUpdateIndex === versionIndex ? "Version updated successfully!" : ""}</p>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  handleVersionSubmit(versionIndex)
                }} className="form-narrow" method="post" id="frames-version-form">
                  <div className="row mb-4 form-group align-items-center">
                    <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                      Product
                    </label>
                    <div className="width-lg-80">
                      <input type="text" className="form-control" required={true} value={version.product} readOnly />
                    </div>
                  </div>
                  <div className="row mb-4 form-group align-items-center">
                    <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                      Version
                    </label>
                    <div className="width-lg-80">
                      <input type="text" className="form-control" required={true} value={version.version} onChange={(e) => {
                        versionDispatch({ type: "set-version", index: versionIndex, version: e.target.value })
                      }} />
                    </div>
                  </div>
                  <div className="row mb-4 form-group align-items-center">
                    <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                      Url for Mac:
                    </label>
                    <div className="width-lg-80">
                      <input type="text" className="form-control" required={true} value={version.osx_link} onChange={(e) => {
                        versionDispatch({ type: "set-osx_link", index: versionIndex, osx_link: e.target.value })
                      }} />
                    </div>
                  </div>
                  <div className="row form-group mb-4">
                    <label htmlFor="staticEmail" className="width-lg-20 col-form-label">
                      Url for Windows:
                    </label>
                    <div className="width-lg-80">
                      <input type="text" className="form-control" required={true} value={version.win_link} onChange={(e) => {
                        versionDispatch({ type: "set-win_link", index: versionIndex, win_link: e.target.value })
                      }} />
                    </div>
                  </div>
                  <div className="row form-group">
                    <label htmlFor="staticEmail" className="width-lg-20 col-form-label"></label>
                    <div className="width-lg-80">
                      <button className="btn btn-primary px-5 py-3" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          })}

        </div>
      </PageWrapper>
    );
  } else {
    return <Navigate to={'/scene-list'} />;
  }
}
