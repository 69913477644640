import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function FileUpload({ file, removeFile, _successCallback }){
	const [uploadStatus, setUploadStatus]= useState(file && file.error ? 'failed' : '');
	const [uploadPercentage, setUploadPercentage]= useState(0);

	useEffect(() => {
		uploadFile();
	}, []);

	const uploadFile = () => {
		if(!file.presignedUrl) return
		axios
			.put(file.presignedUrl, file.file, {
				cancelToken: file.CancelTokenSource.token,
				headers: {
					'Content-Type': ''
				},
				onUploadProgress: p => {
					let percentage = parseInt((p.loaded / p.total) * 100);
					setUploadPercentage(percentage);
					if (percentage === 100) {
						setUploadStatus('success');
						setTimeout(() => {
							_successCallback();
						}, 2000);
					}
				}
			})
			.then(
				result => {
					
				},
				error => {
					if (axios.isCancel(error)) {
						setUploadStatus('canceled');
						setTimeout(() => {
							removeFile();
						}, 1000);
					} else {
						setUploadStatus('failed');
					}
				}
			);	
	}
	return ( 
	<>
		<div className="upload-file mt-1">
			<div className="card">
				<div className="file-link">
					<div className="text-left file-name-status">
						{file.fileName} <br />
						<p className="text-left" data-upload-status={uploadStatus}>
							<span className={
								'uploadStatus text-left ' +
								( file.error ? 'text-danger' : uploadStatus === 'success' ? 'text-success' : '' )} >
								{uploadStatus + ' '+ (file.error ? ' - '+ file.error : '')}
							</span>
						</p>
					</div>
					<p className="per">
						{!file.error && uploadPercentage + '%'}
					</p>
				</div>
				{(uploadPercentage < 100 && !file.error) ? 
					<span
						className="btn btn-danger link btn-cancel"
						onClick={() => {
							file.CancelTokenSource.cancel('Operation canceled by the user.');
						}}
					> Cancel </span> : 
					<span
						className="btn btn-danger link"
						onClick={() => {
							removeFile();
						}}
						> Close
				</span>}
			</div>
		</div>
	</> );
}