import { useEffect } from 'react';
import { useModal } from '../../hooks/modal';

const ImportSceneModal = ({ handleYes, handleNo }) => {
    const modal = useModal();

    useEffect(() => {
        if (!modal.isVisible) {
            modal.show();
        }
    }, []);
    return (
        <div className={'modal fade modal-delete ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered  modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Files with the same name will be overwritten.
                        </h5>
                    </div>
                    <div className="spacer-xs-2"></div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-danger pl-xs-4 pr-xs-4"
                            onClick={() => {
                                handleYes();
                                modal.hide();
                            }}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-link text-content text-bold"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                handleNo();
                                modal.hide();
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ImportSceneModal;
