const ErrorUploadFileRow = ({ name, error }) => {
  return (
    <>
      <div className="upload-file mt-1">
        <div className="card">
          <div className="file-link">
            <div className="text-left file-name-status">
              {name} <br />
              <p className="text-left">{error && <span className="uploadStatus text-danger text-left">Failed - {error}</span>}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ErrorUploadFileRow;
