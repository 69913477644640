import { getAPIPrefix } from '../config/APIEndpoints';
import { _postRequest, _memberOnlyRequest } from './_apiReq';

export const multiMedia = {
  getList(page, perPage, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append("per_page", perPage);
    formData.append("page_number", page);

    _postRequest(
      getAPIPrefix() + 'file-db-list',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  getPresignUrl( fileNames, cb, errorCb) {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("files", fileNames.join("||"));

    _memberOnlyRequest(
      getAPIPrefix() + "file-upload",
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      "Could not upload the file.Please try again!"
    );
  },
  dbSave(fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'file-save';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("fileName", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not save the file in db.Please try again!"
    );
  },
  download(fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'file-download';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_name", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not download the file.Please try again!"
    );
  },
  delete( fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'file-delete';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_name", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not delete the file.Please try again!"
    );
  },

};
