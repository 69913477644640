import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useRegister } from '../../hooks/register';
import Loader from '../Loader';

export default function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  let validation_token = searchParams.get('validation_token');
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const register = useRegister();

  useEffect(() => {
    if (isLoading) {
      verifyEmail();
    }
  }, []);

  const verifyEmail = () => {
    register.verifyEmail(
      validation_token,
      response => {
        setIsLoading(false);
        setSuccess(true);
      },
      error => {
        setIsLoading(false);
        setSuccess(false);
      }
    );
  };

  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center">
            <div className="width-lg-47 width-md-80 width-sm-100 mb-lg-5 text-center">
              <div className="form-wrapper">
                {isLoading ? (
                  <>
                    <Loader />
                    <h5>We are building your wonderful experience.</h5>
                  </>
                ) : success ? (
                  <div>
                    <h5 className="text-success">Email Verified Successfully.</h5>
                    <Link className="text-underline" to={'/signin'}>
                      Sign In
                    </Link>
                  </div>
                ) : (
                  <div>
                    <h5 className="text-danger">Email Verification Failed.</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
