import { useEffect } from 'react';
import { useModal } from '../../hooks/modal';

const SubscriptionSuccessModal = ({ success, handleOk, handleCancel }) => {
  const modal = useModal();

  useEffect(() => {
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);

  return (
    <div className={'modal fade modal-cancel-sub ' + (modal.isVisible ? 'show' : '')} id="cancelSubscription" style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header pb-0"></div>
          <div className="modal-body pt-0">
            <h5 className="modal-title" id="staticBackdropLabel">
              {success ? ' Your subscription successfully updated!' : ' Your subscription update failed!'}
            </h5>
            <div className="d-grid d-md-flex justify-content-md-end">
              <button
                type="button"
                className={'btn ' + (success ? 'btn-primary' : 'btn-danger')}
                onClick={() => {
                  handleOk();
                  modal.hide();
                }}
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionSuccessModal;
