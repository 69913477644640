import { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../services/auth';

export default function ForgotPasswordPage() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const onSubmit = e => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      setError(null);
      setSuccess(null);
      auth.forgotPassword(
        email,
        success => {
          _resetForm();
          setIsLoading(false);
          setSuccess(success.message);
          setTimeout(() => {
            setSuccess(null);
          }, 3000);
        },
        error => {
          setIsLoading(false);
          setError(error.message);
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      );
    }
  };

  const _resetForm = () => {
    document.getElementById('form').reset();
  };

  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center ">
            <div className="width-lg-47 width-md-80 width-sm-100">
              <div className="form-wrapper">
                <form method="post" onSubmit={onSubmit} id="form">
                  <h1>Password Recovery</h1>
                  {error ? <div className="text-danger">{error}</div> : <></>}
                  {success ? <div className="text-success">{success}</div> : <></>}
                  <br />
                  <div className="form-floating">
                    <i className="icon-email"></i>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Work Email"
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      required={true}
                    />
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Work Email
                    </label>
                  </div>
                  <button type="submit" className={'btn btn-primary d-flex justify-content-center w-100 ' + (isLoading ? 'disabled text-white' : '')}>
                    {isLoading ? (
                      <>
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                        <span className="sr-only mt-1">&nbsp; Continue...</span>
                      </>
                    ) : (
                      <span className="sr-only mt-1">&nbsp; Continue</span>
                    )}
                  </button>
                  <div className="spacer-xs-6"></div>
                  <Link to={'/signin'} className="link-primary text-decoration-underline d-flex justify-content-center">
                    Back to Sign in
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
