import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FrameSUpload = ({ CancelTokenSource, file, presignedUrl, error, _uploadCompleteCallback, _successCallback }) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const fnUploadFiles = () => {
    setIsUploading(true);
    if (!presignedUrl) {
      _uploadCompleteCallback();
      return true;
    }
    axios
      .put(presignedUrl, file, {
        cancelToken: CancelTokenSource.token,
        headers: {
          'Content-Type': ''
        },
        onUploadProgress: p => {
          let percentage = parseInt((p.loaded / p.total) * 100);
          setUploadPercentage(percentage);
        }
      })
      .then(
        result => {
          _uploadCompleteCallback();
          if (result.status === 200) {
            setUploadPercentage(100);
            setUploadStatus('success');
            _successCallback();
          }
        },
        error => {
          if (axios.isCancel(error)) {
            setUploadStatus('canceled');
          } else {
            setUploadStatus('failed');
          }
          setShowLoader(false);
          _uploadCompleteCallback(file.name, error);
        }
      );
  };

  useEffect(() => {
    if (!isUploading) {
      fnUploadFiles();
    }
  }, []);
  
  return (
    <>
      {presignedUrl && (
        <div className="upload-file mt-1">
          <div className="card">
            <div className="file-link">
              <div className="text-left file-name-status">
                { file.name} <br />
                <p className="text-left">
                  {error && <span className="uploadStatus text-error text-left">{error}</span>}
                  <span className={'uploadStatus text-left ' + (uploadStatus === 'success' ? 'text-success' : 'text-danger')}>{uploadStatus}</span>
                </p>
              </div>
              <p className="per">{!error && uploadPercentage + '%'}</p>
            </div>
            {!error && uploadPercentage !== 100 && (
              <span
                className="btn btn-danger link"
                onClick={() => {
                  console.log('cancel...');
                  CancelTokenSource.cancel('Operation canceled by the user.');
                }}
              >
                Cancel
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default FrameSUpload;
