export default function NoPage() {
  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center">
            <div className="width-100 text-center">
              <h1 className="text-primary"> Page not found!</h1>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
