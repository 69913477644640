import React, { useReducer, useRef, useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper';
import addFileSVG from "../../images/add-file.svg";
import uploadReducer from "../../reducers/uploadReducer";
import hdriReducer from "../../reducers/hdriReducer";
import FileUpload from "./FileUpload";
import { hdri } from "../../services/hdri";
import { useModal } from "../../hooks/modal";
import Modal from "../modals/Modal";
import axios from "axios";
import moment from 'moment';

const perPage = 20;
let currentPage = 1;
export default function Index() {
	const [uploadFiles, uploadFilesDispatch] = useReducer(uploadReducer, []);
	const [hdriData, dispatch] = useReducer(hdriReducer, {
		items: {},
		isLoading: false,
		error: '',
		hasError: false
	});
	const [deleteModal, setDeletModal] = useState(null);
	const fileInputRef = useRef(null);
	const modal = useModal();

	useEffect(() => {
		dispatch({ type: 'loading', payload: {} });
		_listFiles();
	}, []);

	const _listFiles = () => {
		hdri.getList(
			currentPage,
			perPage,
			(result) => {
				const { list, success, totalPages, per_page } = result;
				if(currentPage !== result.currentPage) return true;

				if (success) {
					dispatch({
						type: 'updateList',
						payload: {
							items: list,
							totalPages,
							perPage: per_page
						}
					});
				}
				},
				(error) => {
					console.log("error:", error);
				}	
	    );
	}

	const handlePreviousClick = () => {
		currentPage = (currentPage - 1);
		_listFiles();
	};

	const handleNextClick = () => {
		currentPage = (currentPage + 1);
		_listFiles();
	};
	
	const changeCurrentPage = (page) => {
		currentPage = page;
		_listFiles();
	}


	const handleUploadChange = (e) => {
	  	if (e.target.files) {
		 	let reqFiles = {};
		 	Object.keys(e.target.files).map((index) => {
		 		let file = e.target.files[index];
				reqFiles[file.name] = file;
			});
			let totalItems = Object.keys(uploadFiles).length;
			
			hdri.getPresignUrl(Object.keys(reqFiles), (res) => {
				if(res.success){
					let temp = [];
					res.result.map((f, i) => {
						const CancelToken = axios.CancelToken;
						const CancelTokenSource = CancelToken.source();
						temp.push({
							...f,
							CancelTokenSource: CancelTokenSource,
							file: reqFiles[f.fileName],
							fileId: totalItems++,
							presignedUrl: f.response
						});
						return true;
					});
					uploadFilesDispatch({ type: 'added', payload: temp });
					fileInputRef.current.value = null;
				}
			}, (error) => {
				console.log("error:", error)
				fileInputRef.current.value = null;
			})
		}
	}

	const removeFile = (fileId) => {
		uploadFilesDispatch({ type: 'remove', payload: { fileId } })
	}

	const downloadFile = (fileName) => {
		hdri.download(
			fileName,
			(data) => {
				const link = document.createElement("a");
				link.href = data.url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
			},
			(error) => {
				alert(error.message);
			}
		);
	};

	const getConfirmation = (fileName) => {
		setDeletModal(
			<Modal
				message={"Are you sure you want to delete this element?"}
				handleOk={() => {
					deleteFile(fileName);
					setDeletModal(null);
				}}
				handleCancel={() => {
					onDeleteReject();
					setDeletModal(null);
				}}
				fileName={fileName}
			/>
		);
	};

	const onDeleteReject = () => {
		setDeletModal(null);
	};

	const deleteFile = (fileName) => {
		hdri.delete(
			fileName,
			(success) => {
				_listFiles();
				modal.hide();
			},
			(error) => {
				alert(error.message);
				modal.hide();
			}
		);
	};

	return (
		<PageWrapper modal={deleteModal ? deleteModal : <></>}>
			<div className={'tab-pane fade show active'} id="pills-models" role="tabpanel" aria-labelledby="pills-models-tab">
				<div className="add-document">
					<div className="document-drop-wrapper">
						<form method="post" id="fileForm" >
							<div className="entry-icon">
								<img src={addFileSVG} />
							</div>
							<span
								className="btn btn-primary"
								onClick={() => {
									fileInputRef && fileInputRef.current.click();
								}}
							>
								<i className="icon-add"></i>Add File
							</span>
							<input type="file" multiple onChange={handleUploadChange} ref={fileInputRef} />
						</form>
					</div>
					<div className="spacer-xs-3"></div>

					{Object.keys(uploadFiles).map((index, key) => {
						let file = uploadFiles[index];
						return (
							<FileUpload 
								file={file} 
								key={'file-' + file.fileName }
								removeFile={() => { removeFile(file.fileId) }}  
								_successCallback={_listFiles}
							/>
						)
					})}
				</div>

				<div className="spacer-xs-3"></div>

				<div className="table-wrapper table-responsive model-table">
					<table className={"table"}>
						<thead>
							<tr>
								<th scope="col">Name</th>
								<th scope="col">Size</th>
								<th scope="col">Modified</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
							{
								hdriData && hdriData.isLoading ? (
									<tr key="no_data_loading">
										<td colSpan="5" className="empty text-center">
											Loading...
										</td>
									</tr>
								) : hdriData && !hdriData.isLoading && Object.keys(hdriData.items).length <= 0 ? (
									<tr key="no_data_found">
										<td colSpan="5" className="empty text-center">
											No Data Found.
										</td>
									</tr>
								) :
									Object.keys(hdriData.items).length &&
									Object.keys(hdriData.items).map((fileName, index) => {
										let item = hdriData.items[fileName];
										if (!item) return <></>;
										let sizeInKB = parseFloat(item.size / 1000).toFixed(1);
										let size = sizeInKB >= 1000 ? (sizeInKB / 1000).toFixed(1) + ' MB' : sizeInKB + ' KB';

										return (
											<tr key={"data_model_" + item.name}>
												<td className="custom-name" width="36%">
													{item.name}
												</td>
												<td> {size} </td>
												<td> {moment(item.lastmodified).format('MMM D, YYYY h:mm A')} </td>
												<td>
													<div className="d-flex align-items-center">
														<button
															className="btn btn-outline-blue rounded-pill me-3"
															onClick={() => {
																downloadFile(item.name);
															}}
														>
															Download
														</button>
														<button
															className="btn btn-outline-danger rounded-pill"
															onClick={() => {
																getConfirmation(item.name);
															}}
														>
															Delete
														</button>
													</div>
												</td>
											</tr>
										);
									})
							}
						</tbody>

					</table>
					<div className="spacer-xs-3"></div>
					<nav aria-label="Page navigation example">
						<ul className="pagination justify-content-center">
							<li className={'page-item link ' + (currentPage <= 1 ? 'disabled' : '')} data-current-page={currentPage}>
								<span
									className="page-link"
									tabIndex="-1"
									onClick={() => {
										if(currentPage <= 1) {
											return true;
										}else{
											handlePreviousClick(currentPage);
										}
									}}
								>
									Previous
								</span>
							</li>
							{hdriData.totalPages === 0 ? (
								<li className="page-item disabled" >
									<span className="page-link ">1</span>
								</li>
							) : (
								[...Array(hdriData.totalPages)].map((item, i) => {
									return (
										<li className={`page-item link ${i + 1 === currentPage ? ' active' : ' disabled'}`} key={`page-${i}`} index={`page-${i}`} onClick={() => changeCurrentPage(i+1)}>
											<span className="page-link ">{i + 1}</span>
										</li>
									);
								})
							)}
							<li
								className={'page-item link ' + (currentPage >= hdriData.totalPages ? 'disabled' : '')}
								data-current-page={currentPage}
								data-total-pages={hdriData.totalPages}
								data-is-last-page={currentPage >= hdriData.totalPages}
							>
								<span
									className="page-link"
									onClick={() => {
										if(currentPage >= hdriData.totalPages) {
											return true;
										}else{
											handleNextClick(currentPage);
										}
									}}
								>
									Next
								</span>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</PageWrapper>
	);
}
