import { useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { auth } from '../../services/auth';
import eye from '../../images/eye.svg';
import eyeSlash from '../../images/eye-slash.svg';

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  let token = searchParams.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let passwordRef = useRef();

  const onSubmit = e => {
    e.preventDefault();

    if (!isLoading) {
      setIsLoading(true);
      setError(null);
      setSuccess(null);
      let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?])[A-Za-z\d()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?]{8,}$/;

      if (!passwordExpression.test(password)) {
        setError(
          "Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|`~!@#$%^&*_-+=;:',<>./?"
        );
        setIsLoading(false);
        return true;
      }

      auth.resetPassword(
        token,
        password,
        success => {
          _resetForm();
          setPassword(null);
          setIsLoading(false);
          setSuccess(success.message ? success.message : 'Password updated successfully.');
          setTimeout(() => {
            setSuccess(null);
          }, 3000);
        },
        error => {
          setPassword(null);
          setIsLoading(false);
          setError(error.message ? error.message : 'Password update failed.');
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      );
    }
  };

  const togglePasswordText = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      passwordRef.current.type = 'password';
    } else {
      setIsPasswordVisible(true);
      passwordRef.current.type = 'text';
    }
  };

  const _resetForm = () => {
    document.getElementById('form').reset();
  };

  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center ">
            <div className="width-lg-47 width-md-80 width-sm-100">
              <div className="form-wrapper">
                <form method="post" onSubmit={onSubmit} id="form">
                  <h1>Set New Password</h1>
                  {error ? <div className="text-danger mb-4">{error}</div> : <></>}
                  {success ? <div className="text-success mb-4">{success}</div> : <></>}

                  <div className="form-floating mb-3">
                    <i className="icon-key"></i>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required={true}
                      name="password"
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      id="floatingPassword"
                      ref={passwordRef}
                      pattern="(?=.*\d)(?=.*[!()[\]{}|\\`~!@#$%^&*_\-+=;:',<>./?])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|\`~!@#$%^&*_\-+=;:',<>./?"
                    />

                    <img
                      className="img-fluid"
                      src={isPasswordVisible ? eye : eyeSlash}
                      alt="icon-seen"
                      onClick={() => {
                        togglePasswordText();
                      }}
                    />
                    <label className="form-label" htmlFor="floatingPassword">
                      Password
                    </label>
                  </div>

                  <button type="submit" className={'btn btn-primary d-flex justify-content-center w-100 ' + (isLoading ? 'disabled text-white' : '')}>
                    {isLoading ? (
                      <>
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                        <span className="sr-only mt-1">&nbsp; Submit...</span>
                      </>
                    ) : (
                      <span className="sr-only mt-1">&nbsp; Submit</span>
                    )}
                  </button>
                  <div className="spacer-xs-6"></div>
                  <Link to={'/'} className="link-primary text-decoration-underline d-flex justify-content-center">
                    Back to Log in
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
