import { useEffect, useState } from 'react';
import { admin } from '../services/admin';
import windows from '../images/windows.svg';
import mac from '../images/mac.svg';

export default function DownloadPage() {
  const [urlForMac, setUrlForMac] = useState('');
  const [urlForWindows, setUrlForWindows] = useState('');

  let url = 'frames://app';

  useEffect(() => {
    if (!urlForMac) {
      admin.getVersion(
        result => {
          setUrlForMac(result.osx_link ? result.osx_link : '');
          setUrlForWindows(result.win_link ? result.win_link : '');
        },
        error => {
          console.log('error:', error);
        }
      );
    }
  }, []);

  const downloadApp = osType => {
    var link = document.createElement('a');
    link.target = '_self';
    link.href = osType === 'mac' ? urlForMac : urlForWindows;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <main className="page-wrapper signin-wrapper">
        <section className="signin">
          <div className="container">
            <div className="row justify-content-lg-end justify-content-center">
              <div className="width-lg-47 width-md-80 width-sm-100">
                <div className="form-wrapper click-here">
                  <form>
                    <div className="link click-box" data-bs-toggle="modal" data-bs-target="#clickDownload">
                      Click to Download
                    </div>
                    <p>While you are waiting for your download, log-in into our web portal to start uploading your 3D assets.</p>
                    <a href="#" className="btn btn-primary d-flex justify-content-center" data-bs-toggle="modal" data-bs-target="#clickDownload">
                      Click here
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        className="modal fade modal-download"
        id="clickDownload"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mb-0">Don’t have 3D Frame installed?</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body pt-0">
              {/* <h4>Don’t have 3D Frame installed?</h4> */}
              <div className="row g-3">
                <div className="width-lg-38">
                  <div className="card link" onClick={() => downloadApp('windows')}>
                    <div className="card-thumb">
                      <img src={windows} className="img-fluid" />
                    </div>
                    <h5 className="link" onClick={() => downloadApp('windows')}>
                      Download for Windows
                    </h5>
                  </div>
                </div>
                <div className="width-lg-38">
                  <div className="card link" onClick={() => downloadApp('mac')}>
                    <div className="card-thumb">
                      <img src={mac} className="img-fluid" />
                    </div>
                    <h5 className="link" onClick={() => downloadApp('mac')}>
                      Download for Mac
                    </h5>
                  </div>
                </div>
              </div>
              {/* <div className="spacer-xs-3"></div>
              <h4>Click the button to proceed with 3D Frame</h4>
              <a href={url} className="btn btn-primary launch">
                Launch the Software
              </a>
              <div className="spacer-xs-6"></div> */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary pl-xs-4 pr-xs-4" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
