import { useEffect } from 'react';
import { useModal } from '../../hooks/modal';

const ErrorModal = ({ message, handleOk, handleCancel }) => {
  const modal = useModal();

  useEffect(() => {
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);

  return (
    <div className={'modal fade modal-delete ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered  modal-md">
        <div className="modal-content">
          <div className="modal-header"></div>

          <div className="modal-body pt-0 pb-0">
            <h5 style={{ fontSize: '1.1rem' }}>{message}</h5>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger pl-xs-4 pr-xs-4"
              onClick={() => {
                handleOk();
                modal.hide();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorModal;
