import { _postRequest } from './_apiReq';

export const stripeSubscription = {
  getInfo(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-subscription-info',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Your subscription does not exist'
    );
  },

  cancelSubscription(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('subscription_id', localStorage.getItem('subscription_id'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-cancel-subscription',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Your subscription cancel request failed.'
    );
  },

  updateSubscription(plan, payment_method_id, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('plan', plan);
    formData.append('payment_method_id', payment_method_id);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-update-subscription',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Your subscription update request failed.'
    );
  },

  retrySubscription(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-retry-subscription',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Your subscription retry request failed.'
    );
  },

  getInvoiceList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-invoice-list',
      formData,
      result => cb(result.invoice ? result.invoice : []),
      error => errorCb({ message: error }),
      'No invoices found.'
    );
  },

  getInvoiceDetail(invoiceId, cb, errorCb) {
    const formData = new FormData();
    formData.append('id', invoiceId);
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-invoice-info',
      formData,
      result => cb(result.invoice ? result.invoice : []),
      error => errorCb({ message: error }),
      'No invoice found.'
    );
  },

  addCard(payment_method_id, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('payment_method_id', payment_method_id);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-card-add',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Add Card failed.'
    );
  },

  deleteCard(payment_method_id, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('payment_method_id', payment_method_id);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/delete-card',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Card deletion succeed.'
    );
  },

  getCardList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-card-list',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'No data found.'
    );
  },

  activateCard(payment_method_id, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('payment_method_id', payment_method_id);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-card-active',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Card activation failed.'
    );
  }
};
