import { Link, useNavigate, useLocation } from "react-router-dom";
import frameLogo from "../images/3dframe-logo.png";
import vectionLogo from "../images/vection-logo.png";
import menuSVG from "../images/menu.svg";
import userSVG from "../images/user.svg";
import { useAuth } from "../hooks/auth";
import { useModal } from "../hooks/modal";
import { useEffect, useState } from "react";
import { user } from "../services/user";
import moment from "moment/moment";
import ReactTooltip from "react-tooltip";
import { stripeSubscription } from "../services/stripeSubscription";
import UserManualModal from "./modals/UserManualModal";

export default function Header(props) {
  const [usage, setUsage] = useState(null);
  const [showUserManualModal, setUserManualModal] = useState(false);
  const [isPlanExpired, setIsPlanExpired] = useState(false);

  let modal = useModal();
  let auth = useAuth();
  let navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    _getInfo();
  });

  const _getInfo = () => {
    if (auth.isLoggedIn() && usage === null) {
      user.getMemoryUsage(
        (response) => {
          if (response) {
            setUsage({ ...response });
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
      stripeSubscription.getInfo(
        (response) => {
          if (response && response.plan_type === "Enterprise") {
            setIsPlanExpired(moment().unix() > response.expiryTime);
          } else {
            setIsPlanExpired(moment().unix() > response.current_period_end);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  };

  return (
    <>
      {showUserManualModal && <UserManualModal 
        onCloseCallback={() => {
            setUserManualModal(false);
          }} />}
      <header>
        <div className="main-header">
          {props.maintenanceMessage && <div className="container-fluid px-4 p-2 text-center text-white custom-text-maintenance">{props.maintenanceMessage}</div>}
          <div className="container-fluid px-4">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="nav-left">
                <Link className="navbar-brand" to={localStorage.getItem("token") ? "/scene-list" : "/"}>
                  <img src={frameLogo} className="img-fluid" />
                </Link>
                <Link className="navbar-brand" to={localStorage.getItem("token") ? "/scene-list" : "/"}>
                  <img src={vectionLogo} className="img-fluid" />
                </Link>
              </div>

              {!props.maintenanceMode ? (
                <div className="nav-right">
                  <div className="d-lg-flex align-items-center d-none">
                    {localStorage.getItem("token") ? (
                      <>
                        <div className="dropdown">
                          <button className="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex">
                              <div className="avatar-xs">
                                <img src={userSVG} className="img-fluid" />
                              </div>
                            </div>
                            <span className="name">Hello! {auth.user ? auth.user.name : ""}</span>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                              <Link to={"/edit-user"} className="dropdown-item">
                                User Info
                              </Link>
                            </li>
                            <li>
                              <Link to={"/change-password"} className="dropdown-item">
                                Change Password
                              </Link>
                            </li>
                            <li>
                              <Link to={"/licence"} className="dropdown-item">
                                License(s) management
                              </Link>
                            </li>
                            <li>
                              <Link to={"/invoices"} className="dropdown-item">
                                Invoices
                              </Link>
                            </li>
                            <li>
                              <span
                                className="dropdown-item text-danger"
                                onClick={() =>
                                  auth.signout(() => {
                                    setUsage(null);
                                    setIsPlanExpired(false);
                                    navigate("/signin");
                                  })
                                }
                              >
                                Logout
                              </span>
                            </li>
                          </ul>
                        </div>
                        <span
                          className="btn btn-primary btn-user-manual me-4"
                          onClick={() => {
                            modal.show();
                            setUserManualModal(true);
                          }}
                        >
                          Download User Manual
                        </span>

                        <Link to={"/download"} className="btn btn-primary me-4">
                          Download APP
                        </Link>
                        <div className="memory d-flex flex-column me-4">
                          <span className="fs-xs-13 text-secondary mb-2 text-center">Memory usage</span>
                          <span className="badge-ultralite rounded-pill">{usage ? usage.memory : " "}</span>
                        </div>
                        <div className="memory d-flex flex-column me-4 align-items-center">
                          <span className="fs-xs-13 text-secondary mb-2 text-center">Current Subscription</span>
                          {isPlanExpired ? (
                            <>
                              <span className="badge-red rounded-pill" data-tip="Your license has expired. Renew your license to continue using the Windows / Mac application">
                                license expired
                              </span>
                              <ReactTooltip place="bottom" />
                            </>
                          ) : (
                            <span className="badge-yellow rounded-pill">{usage ? usage.plan_type : " "}</span>
                          )}
                        </div>
                        {usage && ((usage.plan_type !== "Enterprise" && isPlanExpired) || usage.plan_type === "Free") ? (
                          <button
                            className="btn btn-outline-blue px-5"
                            onClick={() => {
                              navigate("/edit-plan");
                            }}
                          >
                            Upgrade Now!
                          </button>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : pathname === "/signup" ? (
                      <Link to={"/signin"} className="btn btn-primary">
                        Sign In
                      </Link>
                    ) : (
                      <Link to={"/signup"} className="btn btn-primary">
                        Sign Up
                      </Link>
                    )}
                  </div>

                  <div className="mobile-menu">
                    <button className="navbar-toggler menu-toggle dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src={menuSVG} />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      {localStorage.getItem("token") ? (
                        <>
                          <li>
                            <span>Hello! {auth.user ? auth.user.name : ""}</span>
                          </li>

                          <li>
                            <Link to={"/edit-user"} className="dropdown-item">
                              User Info
                            </Link>
                          </li>
                          <li>
                            <Link to={"/change-password"} className="dropdown-item">
                              Change Password
                            </Link>
                          </li>
                          <li>
                            <Link to={"/download"} className="dropdown-item">
                              Download APP
                            </Link>
                          </li>
                          <li>
                            <span
                              // className="btn btn-primary btn-user-manual me-4"
                              onClick={() => {
                                modal.show();
                                setUserManualModal(true);
                              }}
                            >
                              Download User Manual
                            </span>
                          </li>
                          <li>
                            <Link to={"/licence"} className="dropdown-item">
                              License(s) management
                            </Link>
                          </li>
                          <li>
                            <Link to={"/invoices"} className="dropdown-item">
                              Invoices
                            </Link>
                          </li>
                          <li>
                            <span
                              className="dropdown-item text-danger"
                              onClick={() =>
                                auth.signout(() => {
                                  navigate("/signin");
                                })
                              }
                            >
                              Log Out
                            </span>
                          </li>
                        </>
                      ) : pathname === "/signup" ? (
                        <>
                          <li>
                            <Link to={"/signin"} className="dropdown-item text-primary">
                              Sign In
                            </Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <Link to={"/signup"} className="dropdown-item text-primary">
                              Sign Up
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
