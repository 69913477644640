export const getAPIEndpoint = (modelName, reqName) => {
  //
  let partialUrl = process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/';

  if (modelName === 'scene' && ['copy', 'load', 'update', 'add'].includes(reqName)) {
    return partialUrl + reqName + '-' + modelName;
  } else if (modelName === 'scene' && reqName === 'get-details') {
    return partialUrl + 'get-scene-details';
  } else {
    return partialUrl + modelName + '-' + reqName;
  }
};

export const getAPIPrefix = () => {
  return process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/';
};
