import { getAPIPrefix } from '../config/APIEndpoints';
import { _memberOnlyRequest, _postRequest } from './_apiReq';

export const admin = {
  getVersion(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      getAPIPrefix() + 'get-version',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  },

  versionList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      getAPIPrefix() + 'version-list',
      formData,
      result => cb(result.versionlist ? result.versionlist : []),
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  },

  updateVersion(data, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('id', data.id);
    formData.append('version', data.version);
    // formData.append('API_version', data.API_version);
    formData.append('osx_link', data.osx_link);
    formData.append('win_link', data.win_link);
    formData.append('product', data.product);
    _postRequest(
      getAPIPrefix() + 'update-version',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  },
  getFilesList(nextPageToken = null, cb, errorCb) {
    const perPageData = 20;
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('MaxKeys', perPageData);
    if (nextPageToken) {
      formData.append('NextContinuationToken', nextPageToken);
    }

    _postRequest(
      getAPIPrefix() + 'setup-file-list',
      formData,
      result => {
        let xmlData = '';
        var request = new XMLHttpRequest();
        request.open('GET', result.url, true);
        request.overrideMimeType('text/xml');
        request.onload = function () {
          if (request.readyState === request.DONE) {
            if (request.status === 200) {
              xmlData = request.responseText;
              cb({ xmlData: xmlData, ...result });
            }
          }
        };
        request.send(null);
      },
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  },

  getPresignUrl(file_name, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('file_name', file_name);

    _memberOnlyRequest(
      getAPIPrefix() + 'setup-file-upload',
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      'Could not upload the file.Please try again!'
    );
  },

  delete(file_name, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('file_name', file_name);

    _postRequest(
      getAPIPrefix() + 'setup-file-delete',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not delete the file.Please try again!'
    );
  }
};
