import React from 'react';

export default function CardList(props) {
  const { activePaymentMethodId, handleDeleteCard, handleActivateCard, isLoading, isTrialPlan } = props;

  return (
    <>
      <div className="row choose-card">
        <div className="col-12">
          <div className="card-wrapper">
            <div className="card-heading">
              <h5 className="title">Cards List</h5>
            </div>
            <div className="card-content">
              {isLoading ? (
                <p>Loading...</p>
              ) : !props.cards || props.cards.length === 0 ? (
                <>
                  <div className="plan">
                    <p>No cards found. </p>
                  </div>
                </>
              ) : (
                <React.Fragment>
                  {props.cards.map((card, index) => {
                    return (
                      <React.Fragment key={'card-' + index}>
                        <div className={'card ' + (card.payment_method_id === activePaymentMethodId ? 'active' : '')} onClick={() => props.handleCardChange(card)}>
                          <div className="card-body">
                            <span className="text-capitalize">
                              {card.brand}
                              {' **** '} {card.card_number}
                            </span>
                            {card.default ? <span className="btn btn-outline-blue me-3 custom-default-card-button ">Active</span> : <></>}
                            {!card.default ? (
                              <div className="float-right">
                                {!isTrialPlan && (
                                  <a
                                    className="btn btn-outline-blue rounded-pill  pt-0 pb-0"
                                    target="_blank"
                                    onClick={() => {
                                      handleActivateCard(card);
                                    }}
                                  >
                                    Activate
                                  </a>
                                )}
                                &nbsp;&nbsp;
                                <a
                                  className="btn btn-outline-danger rounded-pill pt-0 pb-0 custom-btn-delete-card"
                                  target="_blank"
                                  onClick={() => {
                                    handleDeleteCard(card);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="spacer-xs-2"></div>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )}
              {}
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}
