export default function uploadReducer(files = initialState, action) {
	switch (action.type) {
		case 'added': {
			return [...files, ...action.payload];
		}
		case 'remove': {
			return files.filter( file => file.fileId !== action.payload.fileId )
		}
		case 'uploaded': {
			return files.map((item) => {
				if(item.fileId === action.payload.fileId){
					item.status = "uploaded"
					item.presignedUrl = null;
					return item;
				}else{
					return item;
				}
			})
		}
		default: {
			throw Error('Unknown action: ' + action.type);
		}
	}		
}
const initialState = []