export default function modelReducer(model = initialState, action) {
	switch (action.type) {
		case 'loading': {
			return {
				items: {},
				isLoading: true,
			}
		}
		case 'updatingList': {
			return {
			  ...model,
			  listUpdated: false
			};
		  }
		case 'updateList': {
			return {
				...action.payload,
				isLoading: false,
				listUpdated: true
			};	
		}
		default: 
			return {
				...initialState,
				hasError: true
			};
	}
}
const initialState = {
	items: {},
	isLoading: false,
	error: '',
	hasError: false
}