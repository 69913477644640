import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function FileUpload({ file, dbSaveCall, removeFile, hasError = false }){
	const [uploadStatus, setUploadStatus]= useState(file && file.error ? 'failed' : '');
	const [uploadPercentage, setUploadPercentage]= useState(0);

	useEffect(() => {
		uploadFile();
	}, []);

	const uploadFile = () => {
		if(!file.presignedUrl) return
		let headers = {
			'Content-Type': ''
		}
		if(file.file.type === "application/zip" || file.file.type === "application/x-zip-compressed" ){
			headers['x-amz-tagging'] = 'ProcessMat=Yes'
		}
		axios
			.put(file.presignedUrl, file.file, {
				cancelToken: file.CancelTokenSource.token,
				headers: headers,
				onUploadProgress: p => {
					let percentage = parseInt((p.loaded / p.total) * 100);
					setUploadPercentage(percentage);
					if (percentage === 100) {
						setUploadStatus('success');
					}
				}
			})
			.then(
				result => {
					if(dbSaveCall){
						dbSaveCall();
					}
				},
				error => {
					if (axios.isCancel(error)) {
						setUploadStatus('canceled');
						setTimeout(() => {
							removeFile();
						}, 1000);
					} else {
						setUploadStatus('failed');
					}
				}
			);
	}
	return ( 
	<>
		<div className="upload-file mt-1">
			<div className="card">
				<div className="file-link">
					<div className="text-left file-name-status">
						{file.fileName} <br />
						<p className="text-left" data-upload-status={uploadStatus}>
							<span className={
								'uploadStatus text-left ' +
								( file.error || uploadStatus !== "success" ? 'text-danger' : uploadStatus === 'success' ? 'text-success' : '' )} >
								{uploadStatus + ' '+ (file.error ? ' - '+ file.error : '')}
							</span>
						</p>
					</div>
					<p className="per">
						{!file.error && uploadPercentage + '%'}
					</p>
				</div>
				{(uploadPercentage < 100 && !file.error) ? 
					<span
						className="btn btn-danger link btn-cancel"
						onClick={() => {
							file.CancelTokenSource.cancel('Operation canceled by the user.');
						}}
					> Cancel </span> : 
					<span
						className="btn btn-danger link"
						onClick={() => {
							removeFile();
						}}
						> Close
				</span>}
			</div>
		</div>
	</> );
}