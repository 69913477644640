import { useEffect, useState, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useRegister } from "../../hooks/register";
import { useModal } from "../../hooks/modal";
import eye from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";
import UserManualModal from "../modals/UserManualModal";

export default function SignUp() {
  const [user, setUser] = useState({ username: "", password: "", name: "", email: "", country: "" });
  const [termConditionFlag, setTermConditionFlag] = useState(false);
  const [country, setCountry] = useState(false);
  const [countriesList, setCountriesList] = useState(null);
  const [error, setError] = useState(null);
  const [emailFlag, setEmailFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showUserManualModal, setUserManualModal] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let navigate = useNavigate();
  let register = useRegister();
  let modal = useModal();
  let passwordRef = useRef();

  useEffect(() => {
    register.getCountryList(
      (response) => {
        const { countries } = response;
        setCountriesList(countries);
      },
      (error) => {
        console.log("error:", error);
      }
    );
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setError(null);
    let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?])[A-Za-z\d()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?]{8,}$/;

    if (!passwordExpression.test(user.password)) {
      setError(
        "Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|`~!@#$%^&*_-+=;:',<>./?"
      );
      return true;
    }
    if (!termConditionFlag) {
      setError("Please accept terms and condition.");
      return true;
    }
    if (!country) {
      setError("Please select country.");
      return true;
    }
    setIsLoading(true);
    register.signup(
      user.username,
      user.password,
      user.name,
      user.email,
      country,
      termConditionFlag,
      emailFlag,
      (data) => {
        modal.show();
        setShowWelcomeModal(true);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        setError(error);
      }
    );
  };

  if (localStorage.getItem("token")) {
    return <Navigate to={"/scene-list"} />;
  }

  const togglePasswordText = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      passwordRef.current.type = "password";
    } else {
      setIsPasswordVisible(true);
      passwordRef.current.type = "text";
    }
  };

  return (
    <>
      <main className="page-wrapper signin-wrapper">
        <section className="signin">
          <div className="container">
            <div className="row justify-content-lg-end justify-content-center ">
              <div className="width-lg-47 width-md-80 width-sm-100 mb-lg-5">
                <div className="form-wrapper">
                  <form className="input-from" method="post" onSubmit={onSubmit}>
                    <h1 className="mb-0">
                      Signup for your 14 days <br />
                      free trial
                    </h1>
                    <p className="mb-1">No credit card required. </p>
                    <div className="text-danger mb-3">{error}</div>
                    <div className="form-floating mb-3">
                      <i className="icon-user"></i>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        required={true}
                        value={user.name}
                        onChange={(e) => setUser({ ...user, name: e.target.value })}
                      />
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <i className="icon-email"></i>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required={true}
                        value={user.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                      />
                      <label htmlFor="email" className="form-label">
                        Work Email
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <i className="icon-lock"></i>
                      <input
                        type="text"
                        className="form-control"
                        id="bname"
                        placeholder="User Name"
                        required={true}
                        name="Username"
                        onChange={(e) => setUser({ ...user, username: e.target.value })}
                      />
                      <label htmlFor="bname" className="form-label">
                        User Name
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <i className="icon-glob"></i>
                      <select className="form-select" aria-label="Default select example" onChange={(e) => setCountry(e.target.value)} placeholder="Select Country">
                        <option value=""></option>
                        {countriesList &&
                          countriesList.map((item, key) => {
                            return (
                              <option value={item.id} key={"country-" + item.country_name}>
                                {item.country_name}
                              </option>
                            );
                          })}
                      </select>
                      <label htmlFor="exampleFormControlInput1" className="form-label">
                        Country
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <i className="icon-key"></i>
                      <input
                        id="password"
                        placeholder="Password"
                        className="form-control"
                        type="password"
                        required={true}
                        name="password"
                        onChange={(e) => setUser({ ...user, password: e.target.value })}
                        pattern="(?=.*\d)(?=.*[!()[\]{}|\\`~!@#$%^&*_\-+=;:',<>./?])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|\`~!@#$%^&*_\-+=;:',<>./?"
                        ref={passwordRef}
                      />

                      <img
                        className="img-fluid"
                        src={isPasswordVisible ? eye : eyeSlash}
                        alt="icon-seen"
                        onClick={() => {
                          togglePasswordText();
                        }}
                      />
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                    </div>
                    <div className="spacer-xs-2"></div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" name="terms" id="terms" onChange={(e) => setTermConditionFlag(e.target.checked)} />
                      <label className="form-check-label" htmlFor="gridCheck">
                        I agree to Vection &nbsp;
                        <Link to={"/terms-and-conditions"} className="link-primary text-decoration-underline" target="_blank">
                          Terms
                        </Link>
                        &nbsp; and &nbsp;
                        <Link to={"/privacy-policy"} className="link-primary text-decoration-underline" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="emailUpdate" id="terms" onChange={(e) => setEmailFlag(e.target.checked)} />
                      <label className="form-check-label" htmlFor="gridCheck">
                        I agree to receive Vection news and updates.
                      </label>
                    </div>
                    <div className="spacer-xs-5"></div>
                    {/* <button type="submit" className="btn btn-primary d-flex justify-content-center w-100" value="Get Started Now">
                      Get Started Now
                    </button> */}
                    <button type="submit" className={"btn btn-primary d-flex justify-content-center w-100 " + (isLoading ? "disabled text-white" : "")}>
                      {isLoading ? (
                        <>
                          <div className="spinner-border spinner-border-sm" role="status"></div>
                          <span className="sr-only ">&nbsp; Get Started Now...</span>
                        </>
                      ) : (
                        <span className="sr-only ">&nbsp; Get Started Now</span>
                      )}
                    </button>
                    <span
                      className={"btn btn-primary btn-user-manual d-flex justify-content-center w-100 mt-2 "}
                      onClick={() => {
                        modal.show();
                        setUserManualModal(true);
                      }}
                    >
                      <span className="sr-only">&nbsp; Download User Manual</span>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {showUserManualModal && (
        <UserManualModal
          onCloseCallback={() => {
            setUserManualModal(false);
          }}
        />
      )}
      {showWelcomeModal && (
        <div className={"modal fade modal-mail " + (modal.isVisible ? "show" : "")} style={{ display: modal.isVisible ? "block" : "none" }}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShowWelcomeModal(false);
                    modal.hide();
                    navigate("/signin");
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <h4>Almost there!</h4>
                <p>Check your mailbox to confirm your email address and download 3DFrame to start your 3D and Virtual Reality experience, right inside of Webex.</p>
                <div className="spacer-xs-4"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
