import { useEffect, useState } from 'react';
import { useModal } from '../../hooks/modal';

const DuplicateSceneModal = ({ handleOk, handleCancel, fileName }) => {
  const [newName, setNewName] = useState('');
  const modal = useModal();

  useEffect(() => {
    if (newName === '') {
      setNewName(fileName.replace(/\s\s+/g, ' ').replace(' ', '-') + '1');
    }
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);
  return (
    <div className={'modal fade modal-copy ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered  modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Duplicate Scene of {fileName}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleCancel();
                modal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                New Scene Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                value={newName}
                onChange={e => {
                  setNewName(e.target.value);
                }}
              />
            </div>
            <div className="spacer-xs-8"></div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary pl-xs-3 pr-xs-3"
              onClick={() => {
                handleOk(newName);
                modal.hide();
              }}
            >
              Duplicate
            </button>
            <button
              type="button"
              className="btn btn-link text-danger text-bold"
              data-bs-dismiss="modal"
              onClick={() => {
                handleCancel();
                modal.hide();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DuplicateSceneModal;
