import { useRef, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import eye from '../../images/eye.svg';
import eyeSlash from '../../images/eye-slash.svg';

export default function SignIn() {
  let auth = useAuth();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [user, setUser] = useState({ username: '', password: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let passwordRef = useRef();

  const onSubmit = e => {
    setError(null);
    e.preventDefault();
    setIsLoading(true);
    auth.signin(
      user.username,
      user.password,
      success => {
        if (localStorage.getItem('stripe_status') === 'active') {
          navigate('/scene-list');
        } else {
          navigate('/licence');
        }
      },
      error => {
        // console.log('error', error);
        setError(error.message);
        setIsLoading(false);
      }
    );
  };

  if (localStorage.getItem('token')) {
    return <Navigate to={'/scene-list'} />;
  }

  const togglePasswordText = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      passwordRef.current.type = 'password';
    } else {
      setIsPasswordVisible(true);
      passwordRef.current.type = 'text';
    }
  };

  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-end justify-content-center">
            <div className="width-lg-47 width-md-80 width-sm-100 mb-lg-5">
              <div className="form-wrapper">
                <form className="input-from" method="post" onSubmit={onSubmit}>
                  <h1>Sign In</h1>
                  <div className="text-danger">{error}</div> <br />
                  <div className="form-floating mb-3">
                    <i className="icon-user"></i>
                    <input
                      type="text"
                      className="form-control"
                      required={true}
                      name="username"
                      onChange={e => setUser({ ...user, username: e.target.value })}
                      id="floatingUsername"
                    />
                    <label className="form-label" htmlFor="floatingUsername">
                      User Name
                    </label>
                  </div>
                  <div className="form-floating mb-3">
                    <i className="icon-key"></i>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      required={true}
                      name="password"
                      onChange={e => setUser({ ...user, password: e.target.value })}
                      id="floatingPassword"
                      ref={passwordRef}
                    />

                    <img
                      className="img-fluid"
                      src={isPasswordVisible ? eye : eyeSlash}
                      alt="icon-seen"
                      onClick={() => {
                        togglePasswordText();
                      }}
                    />
                    <label className="form-label" htmlFor="floatingPassword">
                      Password
                    </label>
                  </div>
                  {/* <div class="spacer-xs-5"></div> */}
                  <Link to={'/forgot-password'} className="link link-with-underline">
                    Forgot Password?
                  </Link>
                  <div className="spacer-xs-5"></div>
                  <button type="submit" className={'btn btn-primary d-flex justify-content-center w-100 ' + (isLoading ? 'disabled text-white' : '')}>
                    {isLoading ? (
                      <>
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                        <span className="sr-only mt-1">&nbsp; Sign in...</span>
                      </>
                    ) : (
                      <span className="sr-only mt-1">&nbsp; Sign in</span>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
