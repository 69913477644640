import { _postRequest } from './_apiReq';
import axios from 'axios';

export const auth = {
  signin(username, password, cb, errorCb) {
    const formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/login', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        return response.json();
      })
      .then(
        result => {
          if (result.stripeError) {
            window.location = './licence';
          } else if (result.status === 'inactive') {
            errorCb({ message: 'Inactive user' });
          } else if (result.error) {
            errorCb({ message: result.error });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            errorCb({ message: error.error ? error.error : 'No active keys. Please contact Admin.' });
          } else {
            errorCb({ message: 'Invalid credentials! Please try again.' });
          }
        }
      )
      .catch(error => {
        errorCb({ message: 'Invalid credentials' });
      });
  },

  signout(cb) {
    setTimeout(cb, 100);
  },

  forgotPassword(email, cb, errorCb) {
    const formData = new FormData();
    formData.append('email', email);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/forgot-password', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success) {
            cb(result);
          } else {
            errorCb({ message: result ? result.error : 'Request failed.Please try again!' });
          }
        },
        error => {
          errorCb({ message: 'Request failed.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Request failed.Please try again!' });
      });
  },

  resetPassword(token, password, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('password', password);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/reset-password', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success) {
            cb(result);
          } else {
            errorCb({ message: result ? result.error : 'Request failed.Please try again!' });
          }
        },
        error => {
          errorCb({ message: 'Request failed.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Request failed.Please try again!' });
      });
  },

  changePassword(oldPassword, newPassword, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('oldPassword', oldPassword);
    formData.append('newPassword', newPassword);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/change-password', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success) {
            cb(result);
          } else {
            errorCb({ message: result ? result.message : 'Request failed.Please try again!' });
          }
        },
        error => {
          errorCb(error);
        }
      )
      .catch(error => {
        errorCb({ message: 'Request failed.Please try again!' });
      });
  }
};
