export default function modalReducer(modal, action) {
	switch (action.type) {
		case 'show': {
			return { modal: action.modal };
		}
		case 'hide': {
			return { modal: null };
		}
		default: {
			throw Error('Unknown action: ' + action.type);
		}
	}
}