import { getAPIPrefix } from '../config/APIEndpoints';
import { _postRequest, _memberOnlyRequest } from './_apiReq';

export const framesFile = {
  dbList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      getAPIPrefix() + 'db-list',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  getPresignUrl( file_name, cb, errorCb) {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_name", file_name);

    _memberOnlyRequest(
      getAPIPrefix() + 'setup-file-upload',
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      "Could not upload the file.Please try again!"
    );
  },
  dbSave(fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'admin-save';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("fileName", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not save the file in db.Please try again!"
    );
  },

  getList(page, perPage, nextPageToken = null, cb, errorCb) {
    const formData = new FormData();
    const url = getAPIPrefix() + 'setup-file-list';
    formData.append("token", localStorage.getItem("token"));
    formData.append("MaxKeys", page === 0 ? perPage  : perPage);
    if (nextPageToken) {
      formData.append("NextContinuationToken", nextPageToken);
    }
    
    _postRequest(
      url,
      formData,
      result => {
        let xmlData = "";
        var request = new XMLHttpRequest();
        request.open("GET", result.url, true);
        request.overrideMimeType("text/xml");
        request.onload = function () {
          if (request.readyState === request.DONE) {
            if (request.status === 200) {
              xmlData = request.responseText;
              cb({ xmlData: xmlData, ...result });
            }
          }
        };
        request.send(null);
      },
      errorMessage => {
        errorCb({ message: errorMessage });
      }
    );
  },
  delete( fileName, cb, errorCb) {
    const url = getAPIPrefix() + 'setup-file-delete';
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("file_name", fileName);

    _postRequest(
      url,
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      "Could not delete the file.Please try again!"
    );
  },
 getVersion(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      getAPIPrefix() + 'get-version',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  },

  updateVersion(data, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('id', data.id);
    formData.append('version', data.version);
    // formData.append('API_version', data.API_version);
    formData.append('osx_link', data.osx_link);
    formData.append('win_link', data.win_link);
    formData.append('mandatory', data.mandatory);
    _postRequest(
      getAPIPrefix() + 'update-version',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  },
};
