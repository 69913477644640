import underMaintenanceImg from '../images/exclamation-icon.jpg';

export default function Maintenance() {
  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          <div className="row justify-content-lg-center justify-content-center">
            <div className="width-lg-47 width-md-80 width-sm-100 mb-lg-5 text-center">
              <div className="form-wrapper">
                <div className="row justify-content-lg-end justify-content-center under-maintenance-page">
                  <div className="width-90 text-center">
                    <img src={underMaintenanceImg} height="100" />
                    <h5 className="text-primary">Under Maintenance</h5>
                    <h5 className="text-primary">We'll Be Back Soon</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
