import React, { useReducer, useRef } from 'react';
import ImportSceneModal from '../modals/ImportSceneModal';
import addFileSVG from '../../images/add-file.svg';
import uploadReducer from '../../reducers/uploadReducer';
import FileUpload from './FileUpload';
import Modal from '../modals/Modal';
import modalReducer from '../../reducers/modalReducer'
import axios from 'axios';
import { scene } from '../../services/scene';
import ErrorModal from '../modals/ErrorModal';

export default function FileUploadWrapper(props) {
  const [uploadFiles, uploadFilesDispatch] = useReducer(uploadReducer, []);
  const [modal, modalDispatch] = useReducer(modalReducer, []);
  const fileInputRef = useRef(null);

  const handleUploadChange = e => {
    if (e.target.files) {
      let reqFile = e.target && e.target.files[0];
      if(!reqFile) return;
      
      let totalItems = Object.keys(uploadFiles).length;
      let formatedFileName = reqFile['name'].replace(/[^a-zA-Z0-9. ]/g, ' ').replace(/\s+/g, '-');
      scene.getPresignUrl(
        formatedFileName,
        res => {
          if (res.success) {
            const resultObj = res.result;
            const CancelToken = axios.CancelToken;
            const CancelTokenSource = CancelToken.source();
            let fileObj = [];
            fileObj.push({
              error: resultObj.error,
              fileName: formatedFileName,
              CancelTokenSource: CancelTokenSource,
              file: reqFile,
              fileId: totalItems++,
              presignedUrl: resultObj.response,
              bOverwrite: 'no'
            });
            showImportConfirmationModal(fileObj);
            fileInputRef.current.value = null;
          }
        },
        error => {
          showErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
          console.log('error:', error);
          fileInputRef.current.value = null;
        }
      );
    }
  };

 
  const showImportConfirmationModal = (obj) => {
    // console.log("obj:", obj);
    props._ShowModal(
      <ImportSceneModal
        handleYes={() => {
          modalDispatch({type: 'hide'});
          props._HideModal();
          obj[0]['bOverwrite'] = 'yes';
          uploadFilesDispatch({
            type: 'added',
            payload: obj
          });
        }}
        handleNo={() => {
          modalDispatch({type: 'hide'});
          props._HideModal();
          obj[0]['bOverwrite'] = 'no';
          uploadFilesDispatch({
            type: 'added',
            payload: obj
          });
        }}
      />
    );
  };

  const _uploadSuccessCallback = (file) => {
    let SuccessModal = (
      <Modal
        message="Scene will be imported shortly."
        handleOk={() => {
          modalDispatch({type: 'hide'});
          props._HideModal();
          props.successCallback();
        }}
        okButtonClass="btn-primary"
      />
    );

    scene.importDBSave(
      file.fileName,
      file.bOverwrite,
      response => {
        if (response.success) {
          props._ShowModal(SuccessModal);
        } else {
          showErrorModal(response.Message ? response.Message : 'Something went wrong.Please try again.');
        }
      },
      error => {
        showErrorModal(error.message ? error.message : 'Something went wrong.Please try again.');
      }
    );
  };

  const showErrorModal = message => {
    props._ShowModal(
      <ErrorModal
        message={message}
        handleOk={() => {
          modalDispatch({type: 'hide'});
          props._HideModal();
        }}
        handleCancel={() => {
          modalDispatch({type: 'hide'});
          props._HideModal();
        }}
      />
    );
  };


  const removeFile = fileId => {
    uploadFilesDispatch({ type: 'remove', payload: { fileId } });
  };

  return (
      <>
        <div className="add-document">
          <div className="document-drop-wrapper">
            <form method="post" id="fileForm">
              <div className="entry-icon">
                <img src={addFileSVG} />
              </div>
              <span
                className="btn btn-primary"
                onClick={() => {
                  fileInputRef && fileInputRef.current.click();
                }}
              >
                <i className="icon-add"></i>Import 3DF
              </span>
              <input type="file" onChange={handleUploadChange} ref={fileInputRef} />
            </form>
          </div>

          {Object.keys(uploadFiles).map(index => {
            let file = uploadFiles[index];
            return (
              <FileUpload
                file={file}
                key={'file-' + file.fileName }
                removeFile={() => {
                  removeFile(file.fileId);
                }}
                successCallback={() => {_uploadSuccessCallback(file)}}
              />
            );
          })}
        </div>

        <div className="spacer-xs-3"></div>
      </>
  );
}
