import { useEffect } from 'react';
import { useModal } from '../../hooks/modal';

const Modal = ({ message, handleOk = null, handleCancel = null, fileName = '', okButtonClass = 'btn-danger' }) => {
  const modal = useModal();

  useEffect(() => {
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);

  return (
    <div className={'modal fade modal-delete ' + (modal.isVisible ? 'show' : '')} id="deleteScene" style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered  modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {message}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleCancel(fileName);
                modal.hide();
              }}
            ></button>
          </div>
          <div className="spacer-xs-2"></div>
          <div className="modal-footer">
            {handleOk && (
              <button
                type="button"
                className={'btn  pl-xs-4 pr-xs-4 ' + okButtonClass}
                onClick={() => {
                  handleOk(fileName);
                  modal.hide();
                }}
              >
                Ok
              </button>
            )}
            {handleCancel && (
              <button
                type="button"
                className="btn btn-link text-content text-bold"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleCancel(fileName);
                  modal.hide();
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
