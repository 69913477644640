import { Link } from "react-router-dom";

export default function ExpiredTokenPage() {
  return (
    <main className="page-wrapper signin-wrapper">
    <section className="signin">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="width-lg-47 width-md-80 width-sm-100">
            <div className="form-wrapper text-center">
              <h5>Your session has been expired. Please re-login.</h5>
                <div className="spacer-xs-2"></div>
                <Link to={'/'} className="link-primary text-decoration-underline d-flex justify-content-center">
                  Back to Log in
                </Link>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  );
}
