import PageWrapper from './PageWrapper';

export default function PrivacyPolicyPage() {
  return (
    <PageWrapper hasInvoiceLayout={true}>
      <section className="inner licence">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card comman privacyPolicy">
                <h1>Privacy Policy - Art. 13 GDPR 2016/679 - European Regulation</h1>
                <p>
                  We provide you with information pertaining to the processing and protection of your personal data in implementation of EU Regulation 2016/679 and related
                  applicable regulations.
                </p>
                <p>
                  The Data Controller is Vection Italy S.r.l. Via Azzurra, 41 – 40138 Bologna (BO), reachable via email at info@vection.com.au (from now on “
                  <b>Data Controller or Vection”</b>).
                </p>
                <p>
                  This information applies only to the 3D Frame application (hereinafter "<b>App</b>") and to any websites or web applications used by the Customer of Vection
                  (hereinafter "<b>Customer</b>") who accesses and uses the application.
                </p>
                <p>If you do not agree to the terms as described below, you will not be able to access or use the App, the website or any other aspect of the App's operation.</p>
                <h1>APPLICABILITY OF THIS PRIVACY POLICY</h1>
                <p>
                  This policy does not apply to third party applications or software that integrate with the App. In addition, it is disclosed that the company Customer (the "
                  <b>Customer</b>") that intends to install the App on its platform, receives instructions by means of a separately executed contract (the "<b>Agreement</b>") that
                  governs the access and conditions of use of the App, the processing of messages, the input of files, projects, contact data or contact master data of the Customer
                  and its invitees (collectively, "Customer Data"). Through the Agreement, Vection’s role as the External Data Processor is defined in accordance with the
                  requirements of Article 28 EU 2016/679 as further detailed below.
                </p>
                <h1>‍INFORMATION WE COLLECT AND RECEIVE</h1>
                <p>Frames can collect and receive data and other piece of information in different ways:</p>
                <p>
                  <b>Customer data.</b> The data are registered in the platform at the moment of the conclusion of the Agreement and the acceptance of the contractual conditions.
                  By way of example, but not limited to, the data entered may be contact data (e.g. e-mail address), personal data, job position, employer data... Data of a
                  sensitive nature will not be requested. Anyone who connects with the App is asked to provide only the information necessary for the use of our App. The App is not
                  intended for children under the age of sixteen (16) and we do not knowingly collect personal information from children under the age of 16. If an audit reveals
                  that a child under this age has provided us with personal information, we will promptly delete such personal information from our systems.
                </p>
                <p>
                  <b>Users data.</b> Customers (through its authorized agents) or individuals who have been granted access to a Workspace by a Customer (all hereinafter "
                  <b>Users</b>") submit Data to Frames when they first access and use the App. Information may in fact be provided directly and voluntarily by Users or, if Invited
                  or Viewers through Customer.
                </p>
                <p>
                  <b>Other Information.</b> The Data Controller also collects, generates and/or receives Other Information:
                </p>
                <p>Billing and payment information which may include credit card number, or bank details, billing address and other payment information.</p>
                <h1>Support and Updates</h1>
                <p>We collect usage information for statistical and analytical purposes only, for assistance and support as needed, and to update and maintain the application.</p>
                <p>
                  <b>About Cookies.</b> When you use the App, our servers automatically record information identified as cookies and similar technologies on our Websites and which
                  in some cases, are necessary for operation. For more details on how we use these technologies, please see our Cookie Policy.
                </p>
                <h1>HOW WE USE INFORMATION</h1>
                <p>
                  <b>Customer</b> Personal Data will be processed by the Data Controller for the following purposes:{' '}
                </p>
                <p>
                  1. Correct management of the requested service also for the stipulation, performance and management of the Agreement and the fulfillment of obligations arising
                  from and related to the Agreement itself. Since these treatments are necessary for the definition of the contractual Agreement and for its subsequent
                  implementation, your consent is not required. The data acquired for these purposes are stored by us for the time provided for by the respective regulations (10
                  years, and even longer in case of tax audits)
                </p>
                <p>
                  2. Administrative management, billing, documentation production and all administrative, tax and accounting activities. Contractual relations and legal obligation
                  - 10 years from the last performance
                </p>
                <p>
                  3. For defensive purposes, litigation management and defense in court, the data will be processed for legitimate interest and stored for 10 years from the last
                  occurrence.
                </p>
                <p>
                  4. After-sales service to customers, including a customer satisfaction service with the support of any questionnaires. The data will be processed for legitimate
                  interest and stored until the conclusion of the Agreement
                </p>
                <p>
                  5. To provide, update, maintain and protect our App., prevent or resolve service errors, security, technical or abuse issues, analyze and monitor usage, trends
                  and other activities or at the request of an authorized user
                </p>
                <p>
                  6. We may send you service, technical and other administrative emails, messages and other types of communications. We may also contact you to inform you of
                  changes to our Services, our Service offerings, and important notices related to the Services, such as security and fraud alerts. These communications are
                  considered part of the Services and you may not be required to consent to them.
                </p>
                <p></p>
                <p>
                  Customer uses the FRAMES App according to the terms and conditions included in the Agreement and as required by applicable law. All data entered by the Customer
                  within the App, including personal data of guests/participants may be intercepted even incidentally by Vection as the Customer's Data Controller. The Customer in
                  this case is the Data Controller. An Agreement has been entered into between the Data Controller and the Data Processor, which includes the definition of
                </p>
                <p>
                  If you have received an invitation to participate in a work area, the Client is directly responsible for the protection of your personal data and, in case of
                  doubt, you can contact directly the Client who forwarded the invitation.
                </p>
                <p>
                  Customer may, for example, use App functionality to grant and remove access to a Workspace, assign roles and configure settings, access, modify, export, share and
                  remove Company Data, and otherwise apply its own policies to the Services. In all of the foregoing, Vection is entirely unrelated.
                </p>
                <h1>DATA RETENTION</h1>
                <p>
                  Frames may retain information about you for as long as necessary for the purposes described in this Privacy Policy. This may include retaining other information
                  after you deactivate your account for as long as necessary to pursue the legitimate interests described above or as required by law.
                </p>
                <p>
                  The App will retain User Data in accordance with Customer's instructions. Deletion of Customer Data and other use of the Services by Customer may result in the
                  deletion and/or de-identification of certain associated Other Information. For more details, please contact Customer.
                </p>
                <p></p>
                <h1>HOW WE SHARE AND DISSEMINATE INFORMATION</h1>
                <p>
                  We do not sell your information to third parties or disclose it in exchange for money or other consideration. The communication to third parties is provided, for
                  the achievement of the purposes, to third party companies with which the Data Controller has entered into agreement of External Manager or Sub-Manager according
                  to art.28 EU 2016/679.
                </p>
                <h1>SECURITY</h1>
                <p>
                  Frames implements appropriate security measures to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration and
                  destruction, taking into account the risks associated with the processing and the nature of such data and complying with applicable laws and regulations. Please
                  understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that the information you
                  provide will not be intercepted during transmission to and from us over the Internet. In particular, e-mails sent to or from the App may not be secure, and
                  therefore you must take special care when deciding what information to e-mail to us. Any unauthorized access to or use of this Service or the information
                  collected and maintained by this Service should be brought to our attention immediately by sending us an email at the address above.
                </p>
                <p></p>
                <h1>‍RIGHTS OF THE DATA SUBJECT (ARTICLES 15- 22 of the GDPR)</h1>
                <p>
                  The Data Subject is entitled to the rights set forth in Articles 15 to 22 of the GDPR, where applicable. The Data Subject has the right to request from the Data
                  Controllers access to the Data, the rectification or cancellation of the same or the limitation of their processing or to oppose their processing. For further
                  information on the specifics of the rights of the Data Subject and also to exercise them, it is necessary to send an email or write to the address of the Data
                  Controller, indicated in art.2, detailing the request and the address to which you wish to receive a reply. If you believe that the treatment that concerns you
                  violates the GDPR EU-2016/679 you have the right to complain to the Guarantor for the protection of personal data. Data available on the site
                  www.garanteprivacy.it
                </p>
                <p></p>
                <h1>CHANGES TO THIS POLICY</h1>
                <p>In order to keep this policy up to date, we periodically review it. This cookie policy was last updated on March 11, 2022</p>
                <p></p>
                <p>‍</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
}
