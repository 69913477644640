import React, { useState } from 'react';
import { _parseSceneXML } from '../../utils';
import PageWrapper from '../PageWrapper';

export default function ExportSceneList() {
    const [modal, setModal] = useState(null);

    return (
        <PageWrapper modal={modal}>
            <div className={'tab-pane fade show active'} id="pills-scenes" role="tabpanel" aria-labelledby="pills-scenes-tab">

                <div className="table-wrapper table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="custom-scene-heading">
                                    <span className="heading">Scene</span>
                                </th>
                                <th scope='col'>Created At</th>
                                <th scope='col'>Status</th>
                                <th scope="col" colSpan="2">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={'1'}>
                                <td className="custom-name" width="40%">
                                    Scene 1
                                </td>
                                <td> Nov 9, 2022 12:14 PM</td>
                                <td> <span className={'custom-status-label queue'}>Queued</span></td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        {/* <span
                                            className={`btn btn-outline-blue rounded-pill me-3 disabled not-allowed`}
                                        >
                                            Make Private
                                        </span>
                                        <button className="btn btn-outline-blue rounded-pill me-3">Export</button>
                                        <span className={`btn btn-outline-danger rounded-pill me-3`}>Delete</span> */}
                                        <span className={`btn btn-outline-blue rounded-pill me-3 disabled`}>Download</span>
                                    </div>
                                </td>
                            </tr>
                            <tr key={'2'}>
                                <td className="custom-name" width="40%">
                                    Scene 2
                                </td>
                                <td> Nov 9, 2022 12:14 PM</td>
                                <td> <span className={'custom-status-label processing'}>Processing</span></td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        {/* <span
                                            className={`btn btn-outline-blue rounded-pill me-3 disabled not-allowed`}
                                        >
                                            Make Private
                                        </span>
                                        <button className="btn btn-outline-blue rounded-pill me-3">Export</button>
                                        <span className={`btn btn-outline-danger rounded-pill me-3`}>Delete</span> */}
                                        <span className={`btn btn-outline-blue rounded-pill me-3 disabled`}>Download</span>
                                    </div>
                                </td>
                            </tr>
                            <tr key={'3'}>
                                <td className="custom-name" width="40%">
                                    Scene 3
                                </td>
                                <td> Nov 9, 2022 12:14 PM</td>
                                <td> <span className={'custom-status-label failed'}>Failed</span></td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        {/* <span
                                            className={`btn btn-outline-blue rounded-pill me-3 disabled not-allowed`}
                                        >
                                            Make Private
                                        </span>
                                        <button className="btn btn-outline-blue rounded-pill me-3">Export</button>
                                        <span className={`btn btn-outline-danger rounded-pill me-3`}>Delete</span> */}
                                        <span className={`btn btn-outline-blue rounded-pill me-3 disabled`}>Download</span>
                                    </div>
                                </td>
                            </tr>
                            <tr key={'4'}>
                                <td className="custom-name" width="40%">
                                    Scene 4
                                </td>
                                <td> Nov 9, 2022 12:14 PM</td>
                                <td> <span className={'custom-status-label ready'}>Ready</span></td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        {/* <span
                                            className={`btn btn-outline-blue rounded-pill me-3 disabled not-allowed`}
                                        >
                                            Make Private
                                        </span>
                                        <button className="btn btn-outline-blue rounded-pill me-3">Export</button>
                                        <span className={`btn btn-outline-danger rounded-pill me-3`}>Delete</span> */}
                                        <span className={`btn btn-outline-blue rounded-pill me-3`}>Download</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="spacer-xs-3"></div>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            <li className={'page-item disabled'}>
                                <span className="page-link" tabIndex="-1">
                                    Previous
                                </span>
                            </li>
                            <li className="page-item disabled">
                                <span className="page-link ">1</span>
                            </li>
                            <li className={'page-item '}>
                                <span className="page-link">Next</span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </PageWrapper>
    );
}
