import { useEffect, useState } from 'react';
import { useRegister } from '../../hooks/register';
import { user } from '../../services/user';

export default function EditUserPage() {
  const [userObj, setUserObj] = useState({ username: '', password: '', name: '', email: '', country: '', email_verified: true });
  const [country, setCountry] = useState(false);
  const [countryName, setCountryName] = useState(' ');
  const [clientName, setClientName] = useState('');
  const [countriesList, setCountriesList] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let register = useRegister();

  useEffect(() => {
    _getDetails();
  }, []);

  const _getDetails = async () => {
    user.getInfo(
      response => {
        
        setUserObj({ ...response });
        setCountry(response.country);
        setClientName(response.client_name);
        // all countries list
        register.getCountryList(
          countryResponse => {
            const { countries } = countryResponse;
            setCountriesList(countries);
            countries.map((item, key) => {
              if (item.id === response.country) {
                setCountryName(item.country_name);
              }
            });
          },
          error => {
            console.log('error:', error);
          }
        );
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const onSubmit = e => {
    e.preventDefault();
    setSuccess(null);
    setError(null);
    if (!country) {
      setError('Please select country.');
      return true;
    }
    setIsLoading(true);
    user.update(
      userObj,
      response => {
        setIsLoading(false);
        _getDetails();
        setSuccess(response.message ? response.message : 'Profile updated successfully.');
      },
      error => {
        setIsLoading(false);
        setError(error.message ? error.message : 'Profile update failed.');
      }
    );
  };

  return (
    <main className="page-wrapper signin-wrapper">
      <section className="signin">
        <div className="container">
          {!userObj.email_verified && (
            <div className="row">
              <div className="col-12">
                <div className="alert alert-danger mb-3" role="alert">
                  Please verify your email address.
                </div>
              </div>
            </div>
          )}

          <div className="row justify-content-lg-end justify-content-center">
            <div className="width-100 mb-lg-5">
              <div className="form-wrapper">
                <form className="input-from" method="post" onSubmit={onSubmit} id="form">
                  <h1>User Info</h1>

                  {error ? <div className="text-danger mb-4">{error}</div> : <></>}
                  {success ? <div className="text-success mb-4">{success}</div> : <></>}
                  <div className="form-floating mb-3">
                    <i className="icon-user"></i>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      required={true}
                      value={userObj.name}
                      onChange={e => setUserObj({ ...userObj, name: e.target.value })}
                      disabled={true}
                      style={{ backgroundColor: '#ffffff' }}
                    />
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <i className="icon-lock"></i>
                    <input
                      type="text"
                      className="form-control"
                      id="bname"
                      placeholder="User Name"
                      required={true}
                      name="Username"
                      value={userObj.username}
                      onChange={e => setUserObj({ ...userObj, username: e.target.value })}
                      disabled={true}
                      style={{ backgroundColor: '#ffffff' }}
                    />
                    <label htmlFor="bname" className="form-label">
                      User Name
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    {/* <i className="icon-file-setting"></i>
                    <i className="icon-file-setting"></i>
                    <i className="icon-file-setting"></i> */}
                    {/* <i className="icon-lock"></i> */}
                    {/* <i className="icon-upload3"></i> */}
                    <i className="icon-file-setting"></i>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Client"
                      required={true}
                      name="Client"
                      value={clientName}
                      disabled={true}
                      style={{ backgroundColor: '#ffffff' }}
                    />
                    <label htmlFor="bname" className="form-label">
                      Client
                    </label>
                  </div>

                  <div className="form-floating mb-3">
                    <i className="icon-email"></i>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      required={true}
                      value={userObj.email}
                      onChange={e => setUserObj({ ...userObj, email: e.target.value })}
                      disabled={true}
                      style={{ backgroundColor: '#ffffff' }}
                    />
                    <label htmlFor="email" className="form-label">
                      Work Email
                    </label>
                  </div>
                  <div className="form-floating mb-3">
                    <i className="icon-glob"></i>
                    <input
                      name="text"
                      type="Country"
                      className="form-control"
                      placeholder="Country"
                      required={true}
                      value={countryName}
                      disabled={true}
                      style={{ backgroundColor: '#ffffff' }}
                    />
                    {/* <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={e => {
                          console.log(' e.target.value :', e.target.value);
                          setUserObj({ ...userObj, country: e.target.value });
                        }}
                        placeholder="Select Country"
                        value={userObj.country ? userObj.country : ''}
                        defaultValue={userObj.country ? userObj.country : ''}
                        disabled={true}
                      >
                        <option value=""></option>
                        {countriesList &&
                          countriesList.map((item, key) => {
                            return (
                              <option value={item.id} key={'country-' + item.country_name}>
                                {item.country_name}
                              </option>
                            );
                          })}
                      </select> */}
                    <label htmlFor="exampleFormControlInput1" className="form-label">
                      Country
                    </label>
                  </div>

                  {/* <div className="spacer-xs-5"></div>
                  <button type="submit" className={"btn btn-primary d-flex justify-content-center w-100 " + (isLoading ? "disabled text-white" : "")}>
                    {isLoading ? (
                      <>
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                        <span className="sr-only mt-1">&nbsp; Update...</span>
                      </>
                    ) : (
                      <span className="sr-only mt-1">&nbsp; Update</span>
                    )}
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
