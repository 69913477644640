import { useEffect } from 'react';
import { useModal } from '../../hooks/modal';

const DeleteCardModal = ({ handleOk, handleCancel }) => {
  const modal = useModal();

  useEffect(() => {
    if (!modal.isVisible) {
      modal.show();
    }
  }, []);
  return (
    <div className={'modal fade modal-delete ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered  modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Are you sure you want to delete this card?
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleCancel();
                modal.hide();
              }}
            ></button>
          </div>
          <div className="spacer-xs-2"></div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger pl-xs-4 pr-xs-4"
              onClick={() => {
                handleOk();
                modal.hide();
              }}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-link text-content text-bold"
              data-bs-dismiss="modal"
              onClick={() => {
                handleCancel();
                modal.hide();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteCardModal;
