import { getAPIPrefix } from '../config/APIEndpoints';
import { _postRequest } from './_apiReq';

export const maintenance = {
  getMode(cb, errorCb) {
    _postRequest(
      getAPIPrefix() + 'get-mode',
      [],
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  getMessage(cb, errorCb) {
    _postRequest(
      getAPIPrefix() + 'get-message',
      [],
      result => cb(result),
      error => errorCb({ message: error })
    );
  }
};
