import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import { scene } from '../../services/scene';

export default function EditScene() {
  const [sceneName, setSceneName] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);
  const [isUpdateSceneReqSent, setIsUpdateSceneReqSent] = useState(false);

  let navigate = useNavigate();
  let { name } = useParams();

  useEffect(() => {
    if (name && sceneName === null) {
      setSceneName(name);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (!isUpdateSceneReqSent) {
      setShowLoader(true);
      setIsUpdateSceneReqSent(true);
      scene.update(
        sceneName,
        result => {
          setIsUpdateSceneReqSent(false);
          setMessage('SUBMITTED TO QUEUE.');
          setTimeout(() => {
            navigate('/scene-list');
          }, 2000);
        },
        error => {
          _resetForm();

          setIsUpdateSceneReqSent(false);
          setErrorMessage('Could not create Scene. Try Again!');
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        }
      );
    }
  };

  const _resetForm = () => {
    document.getElementById('edit-scene-form').reset();
    setSceneName(null);
  };

  return (
    <PageWrapper modal={modal}>
      <div className="tab-pane fade show active" id="pills-scenes" role="tabpanel" aria-labelledby="pills-scenes-tab">
        <form onSubmit={handleSubmit} id="edit-scene-form">
          <div className="create-scene-msg messages text-success">{message}</div>
          <div className="create-scene-msg error-message text-danger">{errorMessage}</div>
          <div className="add-name">
            <div className="row align-items-lg-end g-3">
              <div className="width-lg-83">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Scene Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter Scene Name"
                    required={true}
                    onChange={e => setSceneName(e.target.value)}
                    value={sceneName ? sceneName : ''}
                  />
                </div>
              </div>
              <div className="width-lg-17">
                <button className="btn btn-primary d-flex justify-content-center w-100" type="submit">
                  Start
                </button>
              </div>
            </div>
            <div className="spacer-xs-2"></div>
          </div>
        </form>
      </div>
    </PageWrapper>
  );
}
