import React, { useState, useEffect } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { stripeSubscription } from '../../services/stripeSubscription';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../PageWrapper';
import ReviewOrderStep3 from './ReviewOrderStep3';
import DeleteCardModal from '../modals/DeleteCardModal';
import { loadStripe } from '@stripe/stripe-js';
import CardList from './CardList';
import infoSVG from '../../images/info.svg';
import SubscriptionSuccessModal from '../modals/SubscriptionSuccessModal';
import './editPlan.scss';

const Index = props => {
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  return (
    <>
      <Elements stripe={stripe}>
        <EditPlan {...props} />
      </Elements>
    </>
  );
};

function EditPlan() {
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [showCardsLoader, setShowCardsLoader] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [isNewCardActive, setIsNewCardActive] = useState(false);
  const [isReviewingCard, setIsReviewingCard] = useState(false);
  const [activePlan, setActivePlan] = useState('');
  const [activeStep, setActiveStep] = useState(1);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPlanType, setCurrentPlanType] = useState('');
  const [currentPlanDuration, setCurrentPlanDuration] = useState('');
  const [cards, setCards] = useState([]);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState(null);
  const [activePaymentCard, setActivePaymentCard] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [modal, setModal] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const subscriptionRates = {
    'monthly': '50',
    'yearly': '40'
  };
  const license = 1;

  useEffect(() => {
    getInfo();
    getCardList();
  }, []);

  const getInfo = (_cbCardDeletion = false) => {
    stripeSubscription.getInfo(
      response => {
        if (response.success) {
          setCurrentPlan({ ...response });
          if (response.plan_type.toLowerCase() === 'flat') {
            setActivePlan(response.price_id.toLowerCase());
          } else {
            setActivePlan(response.plan_type.toLowerCase());
          }
          setCurrentPlanType(response.plan_type.toLowerCase());
          setCurrentPlanDuration(response.plan_durations.toLowerCase());
        }
      },
      error => {
        console.log('error', error);
      }
    );
  };

  const getCardList = () => {
    let hasDefault = false;
    setShowCardsLoader(true);
    stripeSubscription.getCardList(
      response => {
        setCards(response.cards);
        if (response.cards) {
          setShowCardsLoader(false);
          response.cards.forEach(card => {
            if (card.default) {
              hasDefault = true;
              setActivePaymentCard(card);
              setActivePaymentMethodId(card.payment_method_id);
            }
            return true;
          });
          if (!hasDefault) {
            setActivePaymentCard(response.cards[0]);
            setActivePaymentMethodId(response.cards[0].payment_method_id);
          }
        } else {
          setCards(null);
          setActivePaymentCard(null);
          setActivePaymentMethodId(null);
        }
      },
      error => {
        setShowCardsLoader(false);
        setCards(null);
        setActivePaymentCard(null);
        setActivePaymentMethodId(null);
      }
    );
  };

  const addCard = async e => {
    e.preventDefault();
    setSuccess(null);
    setIsAddingCard(true);
    if (!stripe || !elements) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });

    if (!error) {
      setIsAddingCard(false);
      stripeSubscription.addCard(
        paymentMethod.id,
        success => {
          elements.getElement(CardElement).clear();
          getCardList();
        },
        error => {
          setIsAddingCard(false);
          setError(error.message);
          setTimeout(() => {
            setError(null);
          }, 5000);
          console.log('error:', error);
        }
      );
      setActivePaymentCard(paymentMethod.card);
      setActivePaymentMethodId(paymentMethod.id);
    } else {
      setIsAddingCard(false);
      setError(error.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const handlePlanChange = plan => {
    setActivePlan(plan.toLowerCase());
  };

  const handleStepChange = () => {
    if (activeStep === 1) {
      if (activePlan !== 'free') {
        setActiveStep(2);
      }
    } else if (activeStep === 2 && activePlan !== currentPlanType) {
      if (activePaymentMethodId != null) {
        setActiveStep(3);
      }
    }
  };

  const handleCardChange = card => {
    setActivePaymentCard(card);
    setActivePaymentMethodId(card.payment_method_id);
  };

  const handleActivateCard = card => {
    setShowCardsLoader(true);
    stripeSubscription.activateCard(
      card.payment_method_id,
      success => {
        getCardList();
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const handleDeleteCard = card => {
    let modal = (
      <DeleteCardModal
        handleOk={() => {
          stripeSubscription.deleteCard(
            card.payment_method_id,
            response => {
              getCardList();
            },
            error => {
              console.log('error', error);
            }
          );
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
      />
    );
    setModal(modal);
  };

  const handleConfirmOrder = () => {
    setIsInvoiceLoading(true);
    stripeSubscription.updateSubscription(
      activePlan,
      activePaymentMethodId,
      response => {
        setIsInvoiceLoading(false);
        setPaymentStatus('Succeed');
        _showSubscriptionSuccessModal(true);
      },
      error => {
        setIsInvoiceLoading(false);
        setPaymentStatus('Failed.');
        _showSubscriptionSuccessModal(false);
      }
    );
  };

  const _showSubscriptionSuccessModal = success => {
    let modal = (
      <SubscriptionSuccessModal
        success={success}
        handleOk={() => {
          setModal(null);
          navigate('/licence');
        }}
      />
    );
    setModal(modal);
  };

  return (
    /*  */
    <PageWrapper modal={modal} hasInvoiceLayout={true} showPageLoader={true}>
      <section className="inner edit-plan">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card comman">
                <div className="card-content">
                  <h1>Edit Current Plan</h1>
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className={'nav-link ' + (activeStep === 1 ? ' active' : '')} id="pills-home-tab" type="button">
                            <span></span>
                          </button>
                          <h2>Configure Products</h2>
                        </li>
                        <li className="nav-item" role="presentation">
                          <span className={'nav-link ' + (activeStep === 2 ? ' active' : '')} id="pills-profile-tab">
                            <span></span>
                          </span>
                          <h2>Payment</h2>
                        </li>
                        <li className="nav-item" role="presentation">
                          <span className={'nav-link ' + (activeStep === 3 ? ' active' : '')} id="pills-contact-tab">
                            <span></span>
                          </span>
                          <h2>Review Order</h2>
                        </li>
                      </ul>
                      <div className="tab-content configure-product" id="pills-tabContent">
                        <div className={'tab-pane fade  ' + (activeStep === 1 ? 'show active' : '')} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                          <div className="row">
                            <div className="col-sm-12">
                              <h4 className="entry-heading mb-4">How many licenses do you need?</h4>
                              <div className="licence-num form-group">
                                <input type="number" className="form-control" disabled value={license} />
                                <div className="d-flex align-items-center ms-3">
                                  <p>License(s)</p>
                                  <div
                                    className="info ms-2"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="The License count listed here reflects the total licenses you wish to have on your account after changes are made."
                                  >
                                    <img src={infoSVG} className="img-fluid" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="spacer-xs-6"></div>
                          <div className="row">
                            <div className="col-sm-12">
                              <h4 className="entry-heading mb-4">Choose your subscription cycle</h4>
                            </div>
                          </div>
                          <div className="row choose-plan">
                            {currentPlanType === 'free' ? (
                              <div className="col-sm-4">
                                <div
                                  className={'card custom-clickable ' + (activePlan === 'free' ? 'active' : '')}
                                  onClick={() => {
                                    handlePlanChange('free');
                                  }}
                                >
                                  <div className="card-heading">
                                    <h5>Free</h5>
                                  </div>
                                  <div className="card-content">
                                    <div className="plan">
                                      <p>
                                        <span className="amount">15 days</span>
                                        <span className="d-flex align-items-center">
                                          <span className="per"> &nbsp;</span>
                                        </span>
                                      </p>
                                      <p className="total"> &nbsp;</p>
                                    </div>
                                  </div>
                                  <div className="title">Current Plan</div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-sm-4">
                              <div
                                className={'card custom-clickable ' + (activePlan === 'monthly' ? 'active' : '')}
                                onClick={() => {
                                  handlePlanChange('monthly');
                                }}
                              >
                                <div className="card-heading">
                                  <h5>Flat</h5>
                                </div>
                                <div className="card-content">
                                  <div className="plan">
                                    <p>
                                      <span className="amount">${subscriptionRates['monthly']}</span>
                                      <span className="d-flex align-items-center">
                                        <span className="mo">/mo</span>
                                      </span>
                                    </p>
                                    <p className="total">Billed Monthly</p>
                                  </div>
                                </div>
                                {currentPlanType === 'flat' && currentPlanDuration === 'monthly' ? <div className="title">Current Plan</div> : <></>}
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div
                                className={'card custom-clickable ' + (activePlan === 'yearly' ? 'active' : '')}
                                onClick={() => {
                                  handlePlanChange('yearly');
                                }}
                              >
                                <div className="card-heading">
                                  <h5>Flat</h5>
                                  <span className="badge rounded-pill text-white">Save 20%</span>
                                </div>
                                <div className="card-content">
                                  <div className="plan">
                                    <p>
                                      <span className="amount">${subscriptionRates['yearly']}</span>
                                      <span className="d-flex align-items-center">
                                        <span className="mo">/mo</span>
                                      </span>
                                    </p>
                                    <p className="total">Billed $480 Annually</p>
                                  </div>
                                </div>
                                {currentPlanType === 'flat' && currentPlanDuration === 'yearly' ? <div className="title">Current Plan</div> : <></>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'tab-pane fade payment-tab ' + (activeStep === 2 ? 'show active' : '')} id="pills-profile" role="tabpanel">
                          {/*  */}

                          {success && (
                            <div className="col-6 alert alert-success card-success" role="alert">
                              {success}
                            </div>
                          )}
                          {error && (
                            <div className="col-6 alert alert-danger card-error" role="alert">
                              {error}
                            </div>
                          )}
                          <div className="col-6 add-card-wrapper">
                            <div className="card-wrapper">
                              <div className="card-heading">
                                <h5 className="title">New Card Details</h5>
                              </div>
                              <form onSubmit={addCard}>
                                <CardElement
                                  onFocus={() => {
                                    setIsNewCardActive(true);
                                    // setActivePaymentMethodId('newCard');
                                  }}
                                  onBlur={() => {
                                    setIsNewCardActive(false);
                                  }}
                                  className={'new-card-stripe-element ' + (isNewCardActive ? 'active' : '')}
                                  options={{
                                    hidePostalCode: true,
                                    style: {
                                      base: {
                                        backgroundColor: 'white'
                                      }
                                    }
                                  }}
                                />
                                <button className="pay-button" disabled={!stripe || !elements || isAddingCard}>
                                  {isAddingCard ? 'Adding...' : 'Add'}
                                </button>
                              </form>
                            </div>
                          </div>
                          <div className="spacer-xs-4"></div>

                          <CardList
                            isTrialPlan={currentPlanType === 'free' ? true : false}
                            isLoading={showCardsLoader}
                            cards={cards ? cards : []}
                            activePaymentMethodId={activePaymentMethodId}
                            handleCardChange={handleCardChange}
                            handleDeleteCard={handleDeleteCard}
                            handleActivateCard={handleActivateCard}
                          />
                        </div>
                        <div className={'tab-pane fade ' + (activeStep === 3 ? 'show active' : '')} id="pills-review-order" role="tabpanel">
                          <ReviewOrderStep3 plan={activePlan} paymentStatus={paymentStatus} card={activePaymentCard} isLoading={isInvoiceLoading} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="spacer-xs-6"></div>
                <div className="footer-btn">
                  {activeStep === 1 && (
                    <span
                      href=""
                      className={'btn btn-primary text-white ' + (activePlan === 'free' ? 'disabled' : '')}
                      onClick={() => {
                        handleStepChange();
                      }}
                      aria-disabled={activePlan === 'free'}
                    >
                      Card Details
                    </span>
                  )}
                  {activeStep === 2 && (
                    <span
                      className={
                        'btn btn-primary text-white ' +
                        ((currentPlan && activePlan === currentPlanType) || isReviewingCard || activePaymentMethodId === null || activePaymentMethodId === 'newCard'
                          ? 'disabled'
                          : '')
                      }
                      onClick={() => {
                        setIsReviewingCard(true);
                        handleStepChange();
                      }}
                    >
                      Review Order
                    </span>
                  )}
                  {activeStep === 3 && (
                    <span
                      className={'btn btn-primary text-white ' + (isInvoiceLoading || activePaymentMethodId === null || activePaymentMethodId === 'newCard' ? 'disabled' : '')}
                      onClick={() => {
                        handleConfirmOrder();
                      }}
                    >
                      {isInvoiceLoading ? (
                        <>
                          <div className="spinner-border spinner-border-sm" role="status"></div>
                          <span className="sr-only mt-1">&nbsp; Confirm Order...</span>
                        </>
                      ) : (
                        <span className="sr-only mt-1">&nbsp; Confirm Order</span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
}

export default Index;
