import XMLParser from 'react-xml-parser';
import moment from 'moment';

export const _parseSceneXML = ({ xmlData }) => {
  var xml = new XMLParser().parseFromString(xmlData);
  let fileNames = [];
  let nextContinuationToken = '';
  let continuationToken = '';
  let prefix = '';
  if (xml) {
    xml.children.forEach((element, index) => {
      if (element.name === 'Prefix') {
        prefix = element.value;
      } else if (element.name === 'Contents') {
        let tempName = element.children[0]['value'];
        tempName = tempName.replace(prefix, '');
        let tempNameSplit = tempName.split('/');
        let sceneName = tempNameSplit[0];
        if (sceneName !== '' && !fileNames.includes(sceneName)) {
          fileNames.push(sceneName);
        }
      } else if (element.name === 'NextContinuationToken') {
        nextContinuationToken = element.value;
      } else if (element.name === 'ContinuationToken') {
        continuationToken = element.value;
      }
    });
  }

  let sortedFileNames = [];

  if (fileNames) {
    sortedFileNames = fileNames.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }

  return { sortedFileNames, nextContinuationToken, continuationToken };
};

export const _parseCommonXML = ({ xmlData, dbList, modelName }) => {
  var xml = new XMLParser().parseFromString(xmlData);
  let fileData = {};
  let nextContinuationToken = '';
  let continuationToken = '';
  if (xml) {
    xml.children.forEach((element, index) => {
      if (element.name === 'Contents') {
        let fullName = element.children[0]['value'].split('/');
        let lastModified = element.children[1]['value'];
        let formatedLastModified = moment(lastModified).format('MMM D, YYYY h:mm A');
        let sizeInKB = parseFloat(element.children[3]['value'] / 1000).toFixed(1);
        let name = decodeURIComponent(fullName[fullName.length - 1].replace(/\+/g, ' '));
        let size = sizeInKB >= 1000 ? (sizeInKB / 1000).toFixed(1) + ' MB' : sizeInKB + ' KB';
        if (name.length === 0) {
          return true;
        }
        if (modelName === 'model') {
          if (dbList.includes(name)) {
            fileData[name] = { name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' };
          }
        }
        else if (modelName === 'hdri') {
          if (name.indexOf("thumb_") == -1) {
            fileData[name] = { name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' };
          }
        }
        else {
          fileData[name] = { name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' };
        }
      } else if (element.name === 'NextContinuationToken') {
        nextContinuationToken = element.value;
      } else if (element.name === 'ContinuationToken') {
        continuationToken = element.value;
      }
    });
  }
  let sortedFileNames = Object.keys(fileData).sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
  return { sortedFileNames, fileData, nextContinuationToken, continuationToken };
};

export const _parseAdminXML = ({ xmlData }) => {
  var xml = new XMLParser().parseFromString(xmlData);
  let fileData = [];
  let nextContinuationToken = '';
  let continuationToken = '';
  if (xml) {
    xml.children.forEach((element, index) => {
      if (element.name === 'Contents') {
        let fullName = element.children[0]['value'].split('/');
        let lastModified = element.children[1]['value'];
        let formatedLastModified = moment(lastModified).format('MMM D, YYYY h:mm A');
        let sizeInKB = parseFloat(element.children[3]['value'] / 1000).toFixed(1);
        let name = fullName[fullName.length - 1];
        // let name = decodeURIComponent(fullName[fullName.length - 1].replace(/\+./g, ' '));
        let size = sizeInKB >= 1000 ? (sizeInKB / 1000).toFixed(1) + ' MB' : sizeInKB + ' KB';
        if (name.length === 0) {
          return true;
        }
        fileData.push({ name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' });
      } else if (element.name === 'NextContinuationToken') {
        nextContinuationToken = element.value;
      } else if (element.name === 'ContinuationToken') {
        continuationToken = element.value;
      }
    });
  }
  return { fileData, nextContinuationToken, continuationToken };
};
