export default function sceneReducer(scenes, action) {
  switch (action.type) {
    case 'loading': {
      return {
        ...scenes,
        isLoading: true,
        listUpdated: false
      };
    }
    case 'resetList': {
      return {
        ...scenes,
        items: []
      };
    }
    case 'updatingList': {
      return {
        ...scenes,
        isLoading: false,
        listUpdated: false
      };
    }
    case 'updateList': {
      return {
        ...action.payload,
        isLoading: false,
        listUpdated: true
      };
    }
    case 'updateImage': {
      return {
        ...scenes,
        images:  { ...scenes.images,...action.payload}
      }
    }
    // case 'setImage': {
    //   const { currentPage, imageData } = action.payload;
    //   return {
    //     ...scenes,
    //     paginatedItems: scenes.paginatedItems.map((pageItems, pageIndex) => {
    //       return pageItems.map((item, index) => {
    //         if (imageData.sceneId === item.id && imageData.imageText) {
    //           return { ...item, imageText: imageData.imageText };
    //         } else {
    //           return item;
    //         }
    //       });
    //     })
    //   };
    // }
    case 'setPagination': {
      return {
        ...scenes,
        ...action.payload
      };
    }
    default:
      return {
        ...initialState,
        isLoading: false,
        hasError: true
      };
  }
}
const initialState = {
  images: [],
  paginatedItems: [],
  totalPages: 1,
  perPage: 20,
  items: [],
  isLoading: false,
  error: '',
  hasError: false,
  listUpdated: false
};
