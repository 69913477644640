import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';

export default function PageWrapper(props) {
  const location = useLocation();
  const pathName = location.pathname.replace("/", "");
  const wideContainer = ["scene-list", "model-manager", "hdri-manager", "file-manager", "add-scene", "frame-files", "export-scene-list", "import-scene"].includes(pathName);
  return (
    <>
      <main className="page-wrapper">
        {props.hasInvoiceLayout ? (
          <> {props.children} </>
        ) : (
          <section className={`inner scenes `}>
            <div className={`container ${wideContainer ? "custom-wide-container" : ""}`}>
              <div className="row">
                <div className="col-sm-12">
                  <Navbar />
                </div>
              </div>
              <div className="spacer-xs-3"></div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="tab-content" id="pills-tabContent">
                    {props.children}
                  </div> 
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      {props.modal ? props.modal : <></>}
    </>
  );
}
