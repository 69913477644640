import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper';
import { stripeSubscription } from '../../services/stripeSubscription';
import { useParams } from 'react-router-dom';
import moment from 'moment';

export default function InvoiceDetailsPage() {
  const [invoiceLoading, setIvoiceLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    stripeSubscription.getInvoiceDetail(
      id,
      response => {
        setIvoiceLoading(false);
        setInvoice(response);
      },
      error => {
        setIvoiceLoading(false);
        console.log('error', error);
      }
    );
  }, []);

  return (
    <PageWrapper hasInvoiceLayout={true}>
      <section className="inner licence">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card comman">
                <h1>Invoice Summary</h1>
                <div className="table-wrapper table-responsive">
                  <table className="table invoices-table">
                    <thead>
                      <tr>
                        <th scope="col">Summary</th>
                        <th className="float-right">
                          <a className="btn btn-primary custom-btn-outline-primary-sm" href={invoice ? invoice.hosted_invoice_url : ''} target="_blank">
                            Download PDF
                          </a>
                        </th>
                      </tr>
                    </thead>
                    {invoice && (
                      <tbody>
                        <tr>
                          <td>Billed to</td>
                          <td>{invoice.customer_email}</td>
                        </tr>
                        <tr>
                          <td>Customer Name</td>
                          <td>{invoice.customer_name}</td>
                        </tr>
                        <tr>
                          <td>Currency</td>
                          <td className="text-uppercase">{invoice.currency}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  <div className="spacer-xs-6"></div>
                  <table className="table invoices-table">
                    <tbody>
                      <tr>
                        <td>DURATION</td>
                        <td>DESCRIPTION</td>
                        <td>QTY</td>
                        <td>UNIT PRICE</td>
                        <td>AMOUNT</td>
                      </tr>
                      {invoice &&
                        invoice.lines &&
                        invoice.lines.map((record, index) => {
                          return (
                            <React.Fragment key={'record-' + index}>
                              <tr>
                                <td>
                                  {moment.unix(record.period_start).format('D MMMM YYYY ')} - {moment.unix(record.period_end).format('D MMMM YYYY ')}
                                </td>
                                <td>{record.description}</td>
                                <td>{record.quantity}</td>
                                <td>{record.unit_amount_excluding_tax}</td>
                                <td>{record.amount_excluding_tax}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Subtotal</td>
                        <td>{invoice ? invoice.subtotal : ''}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>{invoice ? invoice.total : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
}
